<template>
	<v-dialog v-model="params.visible"
			  fullscreen
			  content-class="proc-report-signature-dialog">
		<v-card class="proc-report-signature-dialog__card">
			<v-card-title class="d-flex justify-space-between">
				<v-row class="align-center">
					<v-col cols="4">Подпись отчета</v-col>
					<v-col cols="4" class="text-center">Страница: {{ (pdf.page+1) }} из {{ pdf.totalPages }}</v-col>
					<v-col cols="4" class="d-flex justify-end">

						<div class="btns">
							<v-btn @click="onClearPage">Очистить страницу</v-btn>
							<v-btn @click="onSave" color="primary">Сохранить</v-btn>

							<v-btn icon @click="params.visible = false">
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</div>
					</v-col>
				</v-row>
			</v-card-title>

			<v-card-text class="proc-report-signature-dialog__body">
				<div class="proc-report-signature-dialog__doc" id="proc-report-signature-dialog__doc">

				</div>

				<vue-pdf-embed v-if="withPdf" :source="pdf.url"
							   disableTextLayer
							   disableAnnotationLayer
							   :width="pdf.width"
							   :scale="pdf.scale"
							   @loaded="onPdfLoaded"
							   @rendered="onPdfRendered"/>

				<template v-if="pdf.isRendered">
					<v-icon v-if="pdf.page>0" class="arrow-prev" x-large @click="onPrevPage">
						mdi-arrow-left-circle-outline
					</v-icon>
					<v-icon v-if="pdf.page+1<pdf.totalPages" x-large class="arrow-next" @click="onNextPage">
						mdi-arrow-right-circle-outline
					</v-icon>
				</template>
				<div v-else class="abs">
					<div class="d-flex flex-column align-center">
						<v-progress-circular indeterminate
											 color="grey lighten-2"/>
						<p class="mt-8">Пожалуйста, подождите...</p>
					</div>
				</div>
			</v-card-text>
		</v-card>
	</v-dialog>

</template>

<script>
	import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';
	import Config from "@/config";

	const SIGNATURE_ARC_RADIUS = 2;
	const SIGNATURE_COLOR = "#00009955";
	const CANVAS_WIDTH = 100; // the more - the sharper
	const CANVAS_SCALE = 3; // the more - the sharper

	let docEl = null;
	let canvases = null;

	export default {
		name: "proc-report-signature-dialog",
		components: {VuePdfEmbed},
		props: ["params"],
		data: () => ({
			drawing: {
				lastX: null,
				lastY: null,
				currentX: null,
				currentY: null,
				offsetX: null,
				offsetY: null,
				width: null,
				height: null,
				hasSmth: false,
				isDrawing: false,
				scrollTop: 0
			},
			pdf: {
				width: CANVAS_WIDTH,
				scale: CANVAS_SCALE,
				url: null,
				page: 1,
				totalPages: 1,
				isLoaded: false,
				isRendered: false
			},
			withPdf: false
		}),
		watch: {
			"params.visible": {
				immediate: true,
				handler(visible, old) {
					if (visible && visible !== old) {
						this.init();
					} else if (!visible && visible !== old) {
						this.deinit();
					}
				}
			},
		},
		computed: {
			canvases() {
				return canvases;
			},
		},
		methods: {
			draw() {
				const lX = this.drawing.lastX;
				const lY = this.drawing.lastY;
				const cX = this.drawing.currentX;
				const cY = this.drawing.currentY;
				const ctx = this.drawing.ctx;
				const kx = this.drawing.ctx.canvas.width / this.drawing.width;
				const ky = this.drawing.ctx.canvas.height / this.drawing.height;
				for (let i = 0; i < 1; i += 0.1) {
					ctx.beginPath();
					ctx.arc((cX + (lX - cX) * i) * kx, (cY + (lY - cY) * i) * ky, SIGNATURE_ARC_RADIUS, 0, 2 * Math.PI, false);
					ctx.fillStyle = SIGNATURE_COLOR;
					ctx.fill();
				}
			},
			onTouchStart(event) {
				event.preventDefault();

				if (event.touches) {
					this.drawing.lastX = event.touches[0].clientX - this.drawing.offsetX;
					this.drawing.lastY = event.touches[0].clientY - this.drawing.offsetY + this.drawing.scrollTop;
				} else {
					// mouse
					this.drawing.lastX = event.clientX - this.drawing.offsetX;
					this.drawing.lastY = event.clientY - this.drawing.offsetY + this.drawing.scrollTop;
				}
				this.drawing.isDrawing = true;
			},
			onTouchMove(event) {
				if (!this.drawing.isDrawing) return;

				// get current mouse position
				if (event.touches) {
					this.drawing.currentX = event.touches[0].clientX - this.drawing.offsetX;
					this.drawing.currentY = event.touches[0].clientY - this.drawing.offsetY + this.drawing.scrollTop;
				} else {
					// mouse
					this.drawing.currentX = event.clientX - this.drawing.offsetX;
					this.drawing.currentY = event.clientY - this.drawing.offsetY + this.drawing.scrollTop;
				}
				this.draw();

				// set current coordinates to last one
				this.drawing.lastX = this.drawing.currentX;
				this.drawing.lastY = this.drawing.currentY;
			},
			onTouchEnd(event) {
				//this.update(true);
				this.drawing.isDrawing = false;
				this.drawing.hasSmth = true;
			},
			onResize() {
				this.deinitPage();
				this.initHeight().then(()=>{
					this.initPage();
				});
			},
			onPrevPage() {
				if (this.pdf.page > 0) {
					this.deinitPage();
					this.pdf.page--;
					this.initPage();
				}
			},
			onNextPage() {
				if (this.pdf.page + 1 < this.pdf.totalPages) {
					this.deinitPage();
					this.pdf.page++;
					this.initPage();
				}
			},
			onClearPage() {
				this.deinitPage();
				// clone again canvas from pdf
				const pageIndex = this.pdf.page;
				let els = document.querySelectorAll(".vue-pdf-embed canvas");
				els = [...els];
				const canvas = els[pageIndex];
				const c = this.cloneCanvas(canvas);
				canvases.splice(pageIndex, 1, c);
				this.initPage();
			},
			onSave() {
				//this.$emit("saved");
				if (!canvases?.length) return;

				// 1. concat all page canvases in one image
				const combinedCanvas = document.createElement("canvas");
				const combinedCtx = combinedCanvas.getContext("2d");
				const firstPageCanvas = canvases[0];
				const firstPageCtx = firstPageCanvas.getContext("2d");
				combinedCanvas.width = firstPageCtx.canvas.width;
				const height = firstPageCtx.canvas.height;
				combinedCanvas.height = height * canvases.length;
				canvases.forEach((canvas, index) => {
					combinedCtx.drawImage(canvas, 0, index * height);
				});

				/*this.deinitPage();
				combinedCanvas.style.width = "auto";
				combinedCanvas.style.height = this.pdf.height + "px";
				docEl.appendChild(combinedCanvas);*/

				// 2. send image to server
				const data = combinedCanvas.toDataURL();
				this.$emit("save", data);

				this.params.visible = false;
			},
			init() {
				this.deinit();
				this.pdf.url = this.params.url;

				window.addEventListener("resize", this.onResize);
				this.initHeight();
				this.reloadPdf();
				console.log("DIALOG INITED");
			},
			initHeight() {
				return new Promise((resolve, reject) => {
					setTimeout(() => {
						docEl = document.getElementById("proc-report-signature-dialog__doc");
						//docEl.addEventListener("scroll", this.onScroll);
						this.pdf.height = docEl.offsetHeight;
						resolve();
					}, 150);
				});
			},
			cloneCanvas(canvas) {
				const ctx = canvas.getContext('2d');
				const c = document.createElement("canvas");
				//c.setAttribute(width = c.width
				c.width = ctx.canvas.width;
				c.height = ctx.canvas.height;
				//c.style.width = ctx.canvas.width * (this.pdf.height / ctx.canvas.height) + "px";
				//c.style.height = this.pdf.height + "px";
				const destCtx = c.getContext('2d');
				destCtx.drawImage(canvas, 0, 0);

				return c;
			},
			setCanvasStyle(canvas) {
				const ctx = canvas.getContext('2d');
				canvas.style.width = ctx.canvas.width * (this.pdf.height / ctx.canvas.height) + "px";
				canvas.style.height = this.pdf.height + "px";

				console.log("SET STYLE", this.pdf.height);
			},
			reloadPdf() {
				this.withPdf = false;
				this.pdf.isLoaded = false;
				this.pdf.isRendered = false;

				// need this timout to hide canvas if was shown
				setTimeout(() => {
					this.withPdf = true;
				}, 150);
			},
			onPdfLoaded(pdf) {
				//console.log("loaded", pdf);
				this.pdf.isLoaded = true;
				this.pdf.totalPages = pdf._pdfInfo.numPages;
				//this.reinitCanvas();
			},
			onPdfRendered() {
				this.clonePdfCanvases().then(() => {
					this.pdf.isRendered = true;
					this.initPage();
				});
			},
			initPage() {
				const canvas = canvases[this.pdf.page];
				this.setCanvasStyle(canvas);
				docEl.appendChild(canvas);

				this.drawing.offsetX = canvas.getBoundingClientRect().left;
				this.drawing.offsetY = canvas.getBoundingClientRect().top;
				this.drawing.width = canvas.getBoundingClientRect().width;
				this.drawing.height = canvas.getBoundingClientRect().height;
				this.drawing.ctx = canvas.getContext('2d');
				canvas.addEventListener("touchstart", this.onTouchStart);
				canvas.addEventListener("mousedown", this.onTouchStart);
				canvas.addEventListener("touchmove", this.onTouchMove);
				canvas.addEventListener("mousemove", this.onTouchMove);
				canvas.addEventListener("touchend", this.onTouchEnd);
				canvas.addEventListener("mouseup", this.onTouchEnd);
				console.log("CANVAS INITED, page " + this.pdf.page);
			},
			deinitPage() {
				if (canvases) {
					const canvas = canvases[this.pdf.page];
					//const docEl = document.getElementById("proc-report-signature-dialog__doc");
					//docEl.removeEventListener("scroll", this.onScroll);
					canvas.removeEventListener("touchstart", this.onTouchStart);
					canvas.removeEventListener("mousedown", this.onTouchStart);
					canvas.removeEventListener("touchmove", this.onTouchMove);
					canvas.removeEventListener("mousemove", this.onTouchMove);
					canvas.removeEventListener("touchend", this.onTouchEnd);
					canvas.removeEventListener("mouseup", this.onTouchEnd);
					console.log("CANVAS DEINITED");
				}

				if (docEl?.children?.length) docEl.removeChild(docEl.firstChild);
			},
			clonePdfCanvases() {
				return new Promise((resolve, reject) => {
					//this.deinitCanvas();
					// wait fo pdf to make its canvases
					setTimeout(() => {
						canvases = [];
						let els = document.querySelectorAll(".proc-report-signature-dialog__body .vue-pdf-embed canvas");
						els = [...els];
						els.forEach(canvas => {
							const c = this.cloneCanvas(canvas);
							canvases.push(c);
						});
						resolve();
					}, 150);
				});
			},
			deinit() {
				this.deinitPage();
				canvases = null;
				this.withPdf = false;
				this.pdf.page = 0;
				this.drawing.hasSmth = false;
				//this.drawing.scrollTop = 0;
				window.removeEventListener("resize", this.onResize);
				console.log("DIALOG DEINITED");
			},
		},
	}
</script>

<style lang="scss">
	.proc-report-signature-dialog {

		&__card {
			display: flex;
			flex-direction: column;
		}

		&__body {
			position: relative;
			flex: 1;
			display: flex;
			flex-direction: column;
			box-sizing: border-box;
			background: red;
			background: $table-bg-color;
			border: 1px solid $border-color;
			padding: 0 !important;

			.arrow-prev {
				position: absolute;
				left: 80px;
				top: 50%;
				transform: translateX(-50%) translateY(-50%);
				color: $black;
			}

			.arrow-next {
				position: absolute;
				right: 80px;
				top: 50%;
				transform: translateX(50%) translateY(-50%);
				color: $black;
			}

			.vue-pdf-embed {
				position: fixed;
				left: -1000px;
				top: 0;

				/*width: 100%;
				//height: 100%;

				canvas {
					margin: 0 auto;
					!*position: absolute;
					left: 50%;
					top: 50%;
					transform: translateX(-50%) translateY(-50%);*!
				}*/
			}
		}

		&__doc {
			flex: 1;
			max-height: 100%;
			position: relative;
			//overflow-x: hidden;
			//overflow-y: auto;
			display: flex;
			justify-content: center;

			/*		canvas {
						margin: 0 auto;
					}*/
		}

		.v-card__title {
			.btns {
				display: flex;
				justify-content: center;
				text-align: center;

				.v-btn {
					margin-left: 20px;
				}
			}

		}
	}
</style>
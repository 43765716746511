<template>
	<v-dialog v-model="params.isVisible"
			  content-class="page-filter-dialog"
			  :fullscreen="!isLG"
			  scrollable
			  width="500">
		<v-card v-if="params.headers" ref="card" class="page-filter-dialog__card">
			<v-card-actions>
				<v-btn
					color="primary"
					text
					@click="close">
					Отмена
				</v-btn>
				<v-spacer/>
				<v-btn
					color="primary"
					text
					@click="onClear">
					Очистить
				</v-btn>
				<v-btn
					color="primary"
					@click="onApply">
					Применить
				</v-btn>
			</v-card-actions>
			<v-card-title class="page-filter-dialog__title">
				Фильтр и сортировка
			</v-card-title>
			<!--			<v-divider/>-->
			<v-card-text class="pt-4">
				<v-form @submit.prevent="onApply">
					<div v-for="(col, colIndex) of localHeaders" :key="'col'+colIndex"
						 class="page-filter-dialog__col">

						<div class="page-filter-dialog__input">
							<v-checkbox v-if="isCheckbox(col)" v-model="col.search"
										dense
										:label="col.CAPTION"/>
							<v-text-field v-else v-model="col.search"
										  :label="col.CAPTION"
										  :placeholder="col.CAPTION"
										  dense
										  clearable
										  :type="isInteger(col)?'number':'text'"/>
						</div>
						<div class="page-filter-dialog__sort">
							<v-btn icon @click="onSort(col)">
								<v-icon v-if="col._sort==='asc'" color="red">mdi-sort-ascending</v-icon>
								<v-icon v-else-if="col._sort==='desc'" color="red">mdi-sort-descending</v-icon>
								<v-icon v-else color="grey lighten-2">mdi-sort</v-icon>
							</v-btn>
						</div>
					</div>
					<input type="submit" hidden/>
				</v-form>
			</v-card-text>
		</v-card>

		<proc-calendar-dialog :params="calendarDialog" @set="onCalendarSetDate"/>
	</v-dialog>
</template>

<script>
import Vue from "vue";
import ProcCalendarDialog from "@/components/elements/proc-calendar-dialog";
import Config from "@/config";
import {dmy2ymd, ymd2dmy} from "@/utils/date";
import vp from "@/mixins/vp";

export default {
	name: "page-filter-dialog",
	components: {ProcCalendarDialog},
	mixins: [vp],
	props: ["params"],
	data: () => ({
		calendarDialog: {
			isVisible: false,
			item: null,
			date: null,
		},
		localHeaders: null,
	}),
	computed: {
		headers() {
			return this.params?.headers?.filter(el => Number(el.VISIBLE));
		}
	},
	watch: {
		"params.isVisible"(val) {
			if (val) {
				this.focusOnFirstInput();
			}
		},
		headers(val) {
			if (val) {
				// make local copy
				this.localHeaders = this.headers.map(el => ({...el}));
			}
		}
	},
	methods: {
		focusOnFirstInput() {
			setTimeout(() => {
				const card = this.$refs.card;
				if (card) {
					const firstInput = card.$el.querySelector("input");
					if (firstInput) firstInput.focus();
				}
			}, 150);
		},
		isCheckbox(col) {
			return !!col.CHECKBOX;
		},
		isInteger(col) {
			return col.FIELD_TYPE_EX?.match(/^integer/)
				|| col.FIELD_TYPE_EX?.match(/^decimal/);
		},
		close() {
			this.params.isVisible = false;
		},
		onClear() {
			this.localHeaders?.forEach(el => {
				el.search = null;
				el._sort = null;
			});
			this.headers?.forEach(el => {
				el.search = null;
				el._sort = null;
			});
			this.close();
			this.$emit("apply");
		},
		onApply() {
			this.localHeaders.forEach((el, index) => {
				this.headers[index].search = el.search;
				this.headers[index]._sort = el._sort;
			});
			this.close();
			this.$emit("apply");
		},
		/**
		 * todo ask client if this is needed?
		 * @param item
		 */
		onClickCalendar(item) {
			this.calendarDialog.date = dmy2ymd(item.VALUE);
			this.calendarDialog.item = item;
			this.calendarDialog.isVisible = true;
		},
		onCalendarSetDate(value) {
			const item = this.calendarDialog.item;
			Vue.set(item, "VALUE", ymd2dmy(value));
		},
		onSort(col) {
			this.localHeaders.forEach((el, index) => {
				if (el === col) {
					if (col._sort === "asc") Vue.set(col, "_sort", "desc");
					else if (col._sort === "desc") Vue.set(col, "_sort", null);
					else Vue.set(col, "_sort", "asc");
				} else el._sort = null;
			});

		}
	}
}
</script>

<style lang="scss">
.page-filter-dialog {

	/*&__card {
		.v-card__actions {
			background: map-get($grey, "lighten-5");
		}
	}*/

	&__title {
		flex: 0;
		//padding: 10px 16px !important;

		@include up($lg) {
			padding: 0 14px !important;
		}
	}

	&__col {
		padding: .25rem 0 .25rem 0 !important;
		display: flex;
		gap: 1rem;
	}

	&__input {
		flex: 1;
	}

	&__sort {
		flex: 0;
	}
}
</style>
/**
 * Returns merged objects as a sibgle object.
 * TS so far does not support this out of the box.
 */
import Config from '@/config';

export function mergeObjects(...args) {
    let target = args[0];
    for (let i = 1; i < arguments.length; i++) {
        let source = arguments[i];
        for (let key in source) {
            if (source.hasOwnProperty(key)) {
                target[key] = source[key];
            }
        }
    }
    return target;
}

export function debounce(func, wait, immediate) {
    let timeout;
    return function () {
        let context = this, args = arguments;
        let later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        let callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

export function crossDownload(url, method = "GET") {
    return new Promise((resolve, reject) => {
        const req = new XMLHttpRequest();
        method = method || "GET";
        req.open(method, url, true);
        req.responseType = "arraybuffer";

        let authToken = localStorage.getItem(Config.STORAGE_AUTH_TOKEN);
        req.setRequestHeader('Authorization', authToken);

        // Create a binary string from the returned data, then encode it as a data URL.
        const arrayBufferToString = (response) => {
            const uInt8Array = new Uint8Array(response);
            let i = uInt8Array.length;
            const binaryString = new Array(i);
            while (i--) {
                binaryString[i] = String.fromCharCode(uInt8Array[i]);
            }
            return binaryString.join('');
        };

        req.onload = function (event) {
            const blob = req.response;
            let fileName = req.getResponseHeader('Content-Disposition')?.split("filename=")[1];
            if (!fileName) {
                const data = arrayBufferToString(req.response);
                //console.log("DATA", data);
                reject(JSON.parse(data));
                return;
            }

            fileName = decodeURI(fileName.replace(/['"]/g, ''))
            const __fileName = fileName;
            const contentType = req.getResponseHeader("content-type");
            if (window.navigator.msSaveOrOpenBlob) {
                // Internet Explorer
                window.navigator.msSaveOrOpenBlob(new Blob([blob], {type: contentType}), fileName);
            } else {
                const link = document.createElement('a');
                document.body.appendChild(link);
                link.download = __fileName;
                //link.href = window.URL.createObjectURL(blob);

                const data = arrayBufferToString(req.response);
                const base64 = window.btoa(data);
                const mime = contentType ? contentType.split(";")[0] : "text/plain";
                link.href = "data:" + mime + ";base64," + base64;
                link.click();
                document.body.removeChild(link); //remove the link when done
            }

            resolve();
        };
        req.send();
    });
};

export function areSameSets(object1, object2) {
    //console.log("1: ", object1);
    //console.log("2: ", object2);
    if (!object1 && !object2) return true;
    if (object1 && !object2) return false;
    if (!object1 && object2) return false;
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) return false;
    for (let i = 0; i < keys1.length; i++) {
        const key = keys1[i];
        //console.log("\t" + key + "1: " + object1[key]);
        //console.log("\t" + key + "2: " + object2[key]);
        if (object1[key] + '' !== object2[key] + '') return false;
    }
    return true;
};

export function routeOfMenu(menu) {
    if (menu?.ID_FORMNAME && menu?.IS_CARD) return {
        name: "card",
        params: {
            "pageId": menu.ID_MENU,
        },
    };
    else if (menu?.ID_PROCNAME) return {
        name: "page",
        params: {
            "pageId": menu.ID_MENU,
        },
    };
    else if (menu?.ID_FORMNAME) return {
        name: "form",
        params: {
            "pageId": menu.ID_MENU,
        },
    };

    return null;
}

export function colorFromDelphi(value) {
    if (!value) return null;

    const constants = {
        // cbStandardColors
        "clBlack": "#000000",
        "clMaroon": "#800000",
        "clGreen": "#008000",
        "clOlive": "#808000",
        "clNavy": "#000080",
        "clPurple": "#800080",
        "clTeal": "#008080",
        "clGray": "#808080",
        "clSilver": "#C0C0C0",
        "clRed": "#FF0000",
        "clLime": "#00FF00",
        "clYellow": "#FFFF00",
        "clBlue": "#0000FF",
        "clFuchsia": "#FF00FF",
        "clAqua": "#00FFFF",
        "clWhite": "#FFFFFF",

        // cbExtendedColors
        "clMoneyGreen": "#C0DCC0",
        "clSkyBlue": "#A6CAF0",
        "clCream": "#FFFBF0",
        "clMedGray": "#A0A0A4",

        // cbSystemColors (win 7)
        "clActiveBorder": "#B4B4B4",
        "clActiveCaption": "#99B4D1",
        "clAppWorkSpace": "#ABABAB",
        "clBackground": "#000000",
        "clBtnFace": "#F0F0F0",
        "clBtnHighlight": "#FFFFFF",
        "clBtnShadow": "#A0A0A0",
        "clBtnText": "#000000",
        "clCaptionText": "#000000",
        "clGradientActiveCaption": "#B9D1EA",
        "clGradientInactiveCaption": "#D7E4F2",
        "clGrayText": "#6D6D6D",
        "clHighlight": "#3399FF",
        "clHighlightText": "#FFFFFF",
        "clHotLight": "#0066CC",
        "clInactiveBorder": "#F4F7FC",
        "clInactiveCaption": "#BFCDDB",
        "clInactiveCaptionText": "#434E54",
        "clInfoBk": "#FFFFE1",
        "clInfoText": "#000000",
        "clMenu": "#F0F0F0",
        "clMenuBar": "#F0F0F0",
        "clMenuHighlight": "#3399FF",
        "clMenuText": "#000000",
        "clScrollBar": "#C8C8C8",
        "cl3DDkShadow": "#696969",
        "cl3DLight": "#E3E3E3",
        "clWindow": "#FFFFFF",
        "clWindowFrame": "#646464",
        "clWindowText": "#000000",
    };

    if (constants[value]) return constants[value];

    let hex = Number(value).toString(16);
    if (hex.length === 6) hex = hex.substring(4, 6)
        + hex.substring(2, 4)
        + hex.substring(0, 2);
    return "#" + hex;
}

import Vue from 'vue';
import {nf, cf, price, phone, date, datetime, preserveSpaces} from './string';

const filters = {
	nf,
	cf,
	price,
	phone,
	date,
	datetime,
	preserveSpaces,
};

export default () => {
	for (let filterName in filters) {
		if (!filters.hasOwnProperty(filterName)) continue;
		Vue.filter(filterName, filters[filterName]);
	}
}
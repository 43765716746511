import Vue from 'vue';

// default tags
import Proc from "@/components/elements/proc";
Vue.component('proc', Proc);
/*import PanelParent from "@/components/elements/panel-parent";
Vue.component('panel-parent', PanelParent);
import PanelChild from "@/components/elements/panel-child";
Vue.component('panel-child', PanelChild);*/
import Panels from "@/components/elements/panels";
Vue.component('panels', Panels);
import Panel from "@/components/elements/panel";
Vue.component('panel', Panel);
import PanelXS from "@/components/elements/panel-xs";
Vue.component('panel-xs', PanelXS);

<template>
	<div v-if="treeItems" class="proc-tree" :class="componentClass" :id="componentId">

		<v-treeview :active.sync="activeTreeItemIds"
					:open.sync="openTreeItemIds"
					:items="treeItems"
					:item-key="fieldId"
					:item-text="fieldName"
					dense activatable @update:active="onSelect" @dblclick="onItemDblClick">
			<template v-slot:label="{ item, index }">
				<template v-if="editedItem && item[fieldId] === editedItem[fieldId]">
					<input v-model="item[fieldName]"/>
				</template>
				<span v-else :id="componentId+'_label-'+item[fieldId]">{{ item[fieldName] }}</span>
			</template>
		</v-treeview>

	</div>
</template>

<script>
import {nf} from "@/utils/string";
import {iso2DDMMYYYY} from "@/utils/date";
import {debounce} from "lodash";
import vp from "@/mixins/vp";
import Config from "@/config";

let id = 0;

export default {
	name: "proc-tree",
	mixins: [vp],
	props: {
		allHeaders: {},
		items: {},
		height: {},
		value: {},
		meta: {},
		isEditing: {
			type: Boolean,
			default: false
		},
	},
	data: () => ({
		activeTreeItemIds: [],
		openTreeItemIds: [],
		selectedItem: null,
		selectedItemIndex: null,
		editedItem: null,
		editedItemLast: null,	// prev copy of edited item, updated AFTER watched deep changes
		treeItems: null,
		isNextDblClick: false,
		id: null,
		countEditing: 0,
	}),
	computed: {
		procId() {
			return this.meta?.ID_PROCNAME;
		},
		fieldId() {
			return this.meta?.KEYTREE;
		},
		fieldName() {
			return this.allHeaders?.find(el => el.PROCPARAM.match(/NAME/))?.PROCPARAM;
		},
		fieldParent() {
			return this.meta?.PARENTFLD;
		},
		treeHeight() {
			return this.height - 2;
		},
		componentId() {
			return "proc-tree" + this.id;
		},
		componentClass() {
			const c = [];
			if (this.isEditing) c.push("--editing");
			return c.length ? c : null;
		}
	},
	watch: {
		activeTreeItemIds() {
			//console.log("activeTreeItemIds", this.activeTreeItemIds);
			this.fixSelectionVTreeViewBug();
		},
		openTreeItemIds() {
			/*console.log("openTreeItemIds", this.openTreeItemIds.map(id=>{
				const item = this.items.find(el=>el[this.fieldId] === id);
				return item[this.fieldName];
			}));*/
		},
		items: {
			immediate: true,
			handler(val, old) {
				// todo это вызывается дважды на инициализации по immediate и ни разу без immediate:true
				this.initTreeItems();
			}
		},
		value(val) {
			this.selectedItem = val;
			//console.log("VALUE", this.selectedItem);
			if (this.selectedItem?.isNew) {
				this.onNewItemInserted();
			}
		},
		selectedItem(val, old) {
			//console.log("selected item changed", val, old);
			if (val !== old) this.$emit("input", this.selectedItem);
		},
		isEditing(val, old) {
			//console.log("proc-table isEditing()", val, this.selectedItem);

			//console.log("EDITING", val, old, this.selectedItem);
			if (val && !old) {
				if (!this.selectedItem) return;
				this.editedItem = this.selectedItem;
				this.countEditing++;
				//console.log("EDITING!");
				/*setTimeout(() => {
					const input = document.querySelector("#" + this.componentId + " .v-treeview-node__label > input");
					input?.focus();
				}, 150);*/
				/*if ( this.editedItem.isNew ) {
					this.placeNewItemToCorrectParent();
				}*/
			} else if (!val && old) {
				this.editedItem = null;
			}
		},
	},
	methods: {
		/**
		 * Fixes bug of v-treeview - old node keeps --active CSS class, even when is not in this.activeTreeItemIds.
		 * Here we remove selection from all nodes except in this.activeTreeItemIds[0].
		 */
		fixSelectionVTreeViewBug() {
			if ( !this.activeTreeItemIds?.length ) return;
			setTimeout(()=>{
				const id = this.activeTreeItemIds[0];
				const label = document.querySelector("#" + this.componentId + "_label-" + id);
				const correctNode = label.parentNode?.parentNode?.parentNode;
				//console.log("Correct node", correctNode);
				let activeNodes = document.querySelectorAll("#" + this.componentId + " .v-treeview-node--active");
				//console.log("Active nodes", activeNodes);
				[...activeNodes].filter(node=>node !== correctNode).forEach(node=>{
					node.classList.remove("v-treeview-node--active")
					node.classList.remove("primary--text");
				});
			},150);
		},
		/*placeNewItemToCorrectParent() {
			console.log("placeNewItemToCorrectParent...", this.selectedItem, this.activeTreeItemIds);
			const currentIndex = this.items.findIndex(el=>el===this.selectedItem);
			if ( currentIndex === -1 ) return;

			const parentId = this.activeTreeItemIds[0];
			let branch = this.items;
			if ( parentId ) {
				branch = this.items.find(el=>el[this.fieldId] === parentId);
				branch = branch.children;
			}
			this.items.splice(currentIndex, 1)
			branch.push(this.selectedItem);
			console.log("branch", branch);
		},*/
		/**
		 * todo called twice from watch items()...
		 */
		initTreeItems() {
			//console.log("Tree rebuilt");
			this.treeItems = this.items.reduce((root, el) => {
				this.$set(el, "children", this.items.filter(child => child[this.fieldParent] === el[this.fieldId] && child[this.fieldId] !== el[this.fieldId]));
				//if (!el[this.fieldParent] && !el[this.fieldId]) root.push(el);
				if (el[this.fieldParent] === null || el[this.fieldParent] === el[this.fieldId]) root.push(el);
				return root;
			}, []);
		},
		openNode(id) {
			//console.log("openNode", id);
			if (!this.openTreeItemIds.includes(id)) {
				//console.log("not open!");
				const label = document.querySelector("#" + this.componentId + "_label-" + id);
				if (label) {
					//console.log("Label", label);
					const btnSiblings = [...label.parentNode?.parentNode?.parentNode?.childNodes];
					const btn = btnSiblings.find(el => el.tagName.toLowerCase() === "button");
					//console.log("btnSiblings", btnSiblings.map(el=>el.tagName));
					//console.log("btn", btn);
					btn?.click();

					setTimeout(()=>{
						this.initTreeItems();
					},150);
				}
			}

			// todo this does not work after two sequential tree item insertion...
			/*if (!this.openTreeItemIds.includes(id)) {
				this.openTreeItemIds.push(id);
				console.log("Opened", this.openTreeItemIds.map(id=>{
					const item = this.items.find(el=>el[this.fieldId] === id);
					return item[this.fieldName];
				}));
			}*/
		},
		onNewItemInserted() {
			//console.log("onNewItemInserted");
			let parentId = this.activeTreeItemIds[0];
			//if ( !parentId ) parentId = 0;
			//console.log("New item added to tree", parentId);
			this.selectedItem[this.fieldParent] = parentId;
			this.initTreeItems();

			// pause to allow tree being rebuilt
			setTimeout(() => {
				this.openNode(parentId);
			}, 150);
		},
		onSelect(itemIds) {
			const itemId = itemIds[0];
			//console.log("onSelect", itemId);
			const index = this.items.findIndex(el => el[this.fieldId] === itemId);
			const item = this.items[index];
			//console.log("SELECTED", item);
			this.onItemClick(item, index);
		},
		onItemClick(item, index) {
			//console.log("click!");
			if (item) {
				this.$emit("click", item, index);
				this.selectedItem = item;
				this.selectedItemIndex = index;
				if (this.isTouch) return this.onItemDblClick(item, index);
				else {
					// emulate dbl click
					this.isNextDblClick = true;
					setTimeout(() => {
						this.isNextDblClick = false;
					}, 1000);
				}
			} else if (this.isNextDblClick) {
				// emulate dbl click
				this.onItemDblClick(this.selectedItem, this.selectedItemIndex);
			}
		},
		onItemDblClick(item, index) {
			//console.log("dblclick!", item);
			this.$emit("open", item);
		},
	},
	mounted() {
		this.id = id++;
	}
}
</script>

<style lang="scss">
.proc-tree {
	flex: 1;
	height: 100%;
	overflow-y: auto;
	@include scroll();

	&.--editing {
		.v-treeview {
			.v-treeview-node__root:has(> .v-treeview-node__content > .v-treeview-node__label > input) {
				background: $cell-bg-color--edited !important;
			}

			input {
				width: 100%;
				height: 19.5px;
				font-size: $font-size-input;
				outline: none;
				padding: 1px 0;
				//background: $cell-bg-color;
				//background: $cell-bg-color--selected;

				/*&.right {
					text-align: right;
				}
				&.center {
					text-align: center;
				}*/
			}


		}
	}

	.v-treeview {
		font-size: $font-size-input;
		border-top: $border;

		&-node {
			&__root {
			padding-left: 4px;
			padding-right: 4px;
			min-height: 24px !important;
			cursor: pointer;
			transition: background-color .2s;
			user-select: none;

			&::before {
				background-color: transparent !important;
			}

			&:hover {
				background-color: $tr-hover-color;
			}

			&.v-treeview-node--active {
				background-color: $cell-bg-color--selected;
				color: rgba(map-get($shades, 'black'), 0.87) !important; // todo
			}
		}

			&__toggle {
				width: 20px;
			}

			&__level {
			width: 20px;
		}
		}

		.v-icon {
			font-size: 20px;
		}
	}
}

</style>
import config from '@/config'

let state = {
	// данные из БЕ - переустанавливаются при каждом fetchPage
	response: {},	// объект ответа от БЕ в чистом виде
	session: {},	// данные текущей сессии
	sessionTimeout: null,
	sessionLastTime: null,
	common: {},			// общие для всех страниц
	page: {},			// текущая страница
	error: {},			// ошибка чтения страницы
	currentRouteName: null,

	// реактивные размеры экрана
	windowWidth: window.innerWidth,
	windowHeight: window.innerHeight,
	//resizeCounters: [],	// child procs watch this to force resize
	resizeCounter: 0,	// child procs watch this to force resize

	isLoading: false,
	withAdaptiveTables: true,

	withDrawer: false,
	info: null,
	filesDialog: {
		item: null,
		files: null,
		currentIndex: null,
		isVisible: false,
	},

	menuType: 1,
	menuItems: null,
	isMenuShown: true,
	currentPanelXS: null,

	pageRoutes: [],
	pageRouteIndex: null,

	/**
	 * Хранит описания всех текущих загруженных выборок - для общения выборок между друг другом.
	 * {
	 * 	procNameId, // ID выборки
	 * 	cols,	// колонки выборки
	 * 	item,	// выбранный элемент выборки
	 * 	isReadonly,	// элементы выборки нельзя редактировать
	 * 	shouldEnterEditMode,	// компоненты (все, кроме proc) устанавливают это в true для перевода элемента в режим редактирования
	 * 	... см. полный список в proc.vue registerProc();
	 * }
	 */
	formProcs: [],
	procIdsWithRefreshItem: [],
	procIdsWithRefresh: [],
	procsShouldRunOperation: [],	// {formId, procId, operationId}
	//procAvailableCols: [],
	editedItem: {
		formId: null,
		procId: null,
		item: null
	}
};

export default state;

export default {
	computed: {
		isXS() {
			return this.$store.getters['isWindowXS'];
		},
		isSM() {
			return this.$store.getters['isWindowSM'];
		},
		isMD() {
			return this.$store.getters['isWindowMD'];
		},
		isLG() {
			return this.$store.getters['isWindowLG'];
		},
		isXL() {
			return this.$store.getters['isWindowXL'];
		},
		isTouch() {
			return (('ontouchstart' in window) ||
				(navigator.maxTouchPoints > 0) ||
				(navigator.msMaxTouchPoints > 0));
		}
	}
}
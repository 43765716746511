<script>
export default {
	methods: {
		onKeyDown(e) {
			if (e.keyCode === 27) {
				this.onEscape();
				return true;
			}
		},
		onEscape() {
			// do nothing by default
		}
	},
	mounted() {
		document.addEventListener('keydown', this.onKeyDown);
	},
	beforeDestroy() {
		document.removeEventListener('keydown', this.onKeyDown);
	},
}
</script>

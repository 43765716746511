import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

// Translation provided by Vuetify (javascript)
import ru from 'vuetify/lib/locale/ru'
import en from 'vuetify/lib/locale/en'

export default new Vuetify({
	lang: {
		locales: { ru, en },
		current: 'ru',
	},
});

<template>
	<div class="proc" :data-debug-proc-id="procNameId"
		 :class="componentClass">

		<template v-if="areAllPFBParamsSet">
			<div v-if="withMobileHeader" class="proc__header flex-grow-0 d-flex align-center justify-space-between"
				 ref="proc__header">
				<h3 class="text-overflow">{{ isDebug ? procNameId + ": " : "" }}{{ title }}</h3>

				<v-btn icon
					   @click="onClickFilter"
					   title="Фильтр">
					<v-icon :color="hasFilters ? 'red' : null">
						{{ hasFilters ? 'mdi-filter' : 'mdi-filter-outline' }}
					</v-icon>
				</v-btn>

				<v-btn v-if="paramsText" icon
					   @click="onClickParams"
					   title="Параметры">
					<v-icon :color="hasParams ? 'red' : null">
						{{ hasParams ? 'mdi-cog' : 'mdi-cog-outline' }}
					</v-icon>
				</v-btn>
			</div>

			<div class="proc__body">
				<div v-if="!hideButtons"
					 class="proc__btns" ref="proc__btns">

					<div v-if="isLG" class="proc__btns-content --desktop">
						<div class="proc__btns-content-left">

							<v-btn v-if="isEditing" color="secondary"
								   :disabled="isUpdating" text
								   @click="onCancelEdit" title="Отмена">
								<v-icon>
									mdi-close
								</v-icon>
							</v-btn>
							<v-btn v-else
								   :disabled="isEditing || !canCreate || hasGlobalEditedItemOfAnotherProc" text
								   @click="onAdd" :loading="isBtnAddLoading"
								   title="Добавить запись">
								<v-icon>
									mdi-plus
								</v-icon>
							</v-btn>

							<v-btn v-if="isEditing" color="primary"
								   :loading="isUpdating" text
								   @click="onSave" title="Сохранить">
								<v-icon>
									mdi-content-save
								</v-icon>
							</v-btn>
							<v-btn v-else class="d-none d-lg-inline"
								   :disabled="!canUpdate || hasGlobalEditedItemOfAnotherProc"
								   :loading="isUpdating"
								   text
								   @click="onEdit"
								   title="Редактировать">
								<v-icon>
									mdi-pencil
								</v-icon>
							</v-btn>

							<template v-if="!isEditing">
								<div class="d-none d-lg-flex flex-nowrap">
									<v-btn :disabled="!canDelete||hasGlobalEditedItem" text
										   @click="onDelete">
										<v-icon>
											mdi-delete-outline
										</v-icon>
									</v-btn>
									<v-btn :disabled="!canRefreshItem||hasGlobalEditedItem" text
										   :loading="isRefetchingCurrentItem"
										   @click="refetchCurrentItem"
										   title="Перечитать элемент">
										<v-icon>
											mdi-refresh
										</v-icon>
									</v-btn>
									<v-btn v-if="withReport"
										   :disabled="!canReport||hasGlobalEditedItem"
										   :loading="isLoading"
										   text
										   @click="onReport"
										   title="Получить отчет">
										<v-icon>
											mdi-text-box-outline
										</v-icon>
									</v-btn>
								</div>

								<proc-btns :buttons="buttons"
										   :current-item="currentItem"
										   :headers="headers"
										   :update-counter="btnsRefreshCounter"
										   @click="onButton"/>

							</template>
						</div>
						<div class="proc__btns-content-right ml-4">
							<h3 class="d-none d-lg-block text-no-wrap mr-2">{{
									isDebug ? procNameId + ": " : ""
								}}{{ title }}</h3>
							<!--						<v-btn :disabled="!canRefresh||isEditing" text
														   class="mb-2 mb-sm-0" @click="onSearch" title="Поиск">
														<v-icon small>
															mdi-magnify
														</v-icon>
													</v-btn>-->
							<a v-if="meta && meta.HELPURL" :href="meta.HELPURL" target="_blank" title="Справка">
								<v-btn text
									   class="mb-2 mb-sm-0">
									<v-icon>
										mdi-help-circle-outline
									</v-icon>
								</v-btn>
							</a>
							<v-btn :disabled="!canRefresh||isEditing||hasGlobalEditedItem" :loading="isLoading" text
								   class="mb-2 mb-sm-0" @click="fetchProc" title="Перезагрузить выборку">
								<v-icon>
									mdi-database-refresh-outline
								</v-icon>
							</v-btn>
						</div>
					</div>
					<div v-else class="proc__btns-content --mobile">
						<div class="proc__btns-content-left">
							<h3 v-if="!withMobileHeader" class="text-overflow mr-2">{{
									isDebug ? procNameId + ": " : ""
								}}{{ title }}</h3>
						</div>
						<div class="proc__btns-content-right ml-4">
							<template v-if="isEditing">
								<v-btn color="primary"
									   :loading="isUpdating" text
									   @click="onSave" title="Сохранить">
									<v-icon>
										mdi-content-save
									</v-icon>
								</v-btn>
								<v-btn color="secondary"
									   :disabled="isUpdating" icon
									   @click="onCancelEdit" title="Отмена">
									<v-icon>
										mdi-close
									</v-icon>
								</v-btn>
							</template>
							<template v-else>
								<v-btn :disabled="!canCreate || hasGlobalEditedItemOfAnotherProc" icon
									   @click="onAdd" :loading="isBtnAddLoading"
									   title="Добавить запись">
									<v-icon>
										mdi-plus
									</v-icon>
								</v-btn>

								<v-btn icon
									   @click="onClickFilter"
									   title="Фильтр">
									<v-icon :color="hasFilters ? 'red' : null">
										{{ hasFilters ? 'mdi-filter' : 'mdi-filter-outline' }}
									</v-icon>
								</v-btn>

								<v-btn v-if="paramsText" icon
									   @click="onClickParams"
									   title="Параметры">
									<v-icon :color="hasParams ? 'red' : null">
										{{ hasParams ? 'mdi-cog' : 'mdi-cog-outline' }}
									</v-icon>
								</v-btn>

								<proc-btns-mobile :buttons="buttons"
												  :meta="meta"
												  :current-item="currentItem"
												  :headers="headers"
												  :update-counter="btnsRefreshCounter"
												  @reload="fetchProc"
												  @click="onButton"/>
							</template>
						</div>
					</div>

					<div v-if="withSearch" ref="search" class="py-4">
						<v-text-field v-model="search" maxlength="64" hide-details dense label="Поиск"/>
					</div>
				</div>

				<div v-if="paramsText && isLG" class="proc__params flex-grow-0">
					<v-text-field v-model="paramsText"
								  class="proc__params-text"
								  readonly dense hide-details
								  placeholder="Параметры"
								  outlined flat filled elevation="0"
								  @click="onClickParams"/>
				</div>

				<div v-if="isLoading" class="flex-grow-1 d-flex justify-center py-8 proc_loader">
					<v-progress-linear
						indeterminate
						rounded
						height="2"
						color="light-blue"/>
				</div>
				<div v-else class="flex-grow-1 proc__content" ref="proc__content">
					<template v-if="isPageReady">
						<proc-tree v-if="isTreeView" class="proc__tree"
								   v-model="currentItem"
								   :all-headers="headers"
								   :items="items"
								   :meta="meta"
								   :height="contentHeight"
								   :hide-buttons="hideButtons"
								   :is-editing="isEditing"
								   @save="onSave"
								   @click="onClick"
								   @open="onOpen"/>
						<proc-table v-else class="proc__table"
									v-model="currentItem"
									:proc-id="procNameId"
									:form-id="formId"
									:page="pageIndex"
									:all-headers="headers"
									:items="items"
									:multiselectedItems="multiselectedItems"
									:style="{width:tableWidth+'px'}"
									:height="contentHeight"
									:readonly="isReadonly"
									:hide-buttons="hideButtons"
									:is-editing="isEditing"
									:search="search"
									:filterCounter="filterCounter"
									:footer-refresh="tableFooterRefreshCounter"
									@click="onClick"
									@item-menu="onItemMenu"
									@page="onPage"
									@open="onOpen"
									@date="onDate"
									@save="onSave"
									@validate="onValidate"
									@multiselect="onMultiselect"
									@selector="onSelector"/>
					</template>
				</div>
			</div>
		</template>
		<h3 v-else class="proc__title">Выберите родительский элемент</h3>

		<div class="proc__fade">
			<!--			<v-progress-circular v-if="isDisabled"
							indeterminate
							rounded
							color="light-blue"/>-->
		</div>

		<v-snackbar v-model="hasInfo" :top="isLG" :bottom="!isLG">
			<template v-slot:action="{ attrs }">
				<v-btn
					color="pink"
					icon
					v-bind="attrs"
					@click="info = null">
					<v-icon>
						mdi-close
					</v-icon>
				</v-btn>
			</template>
			{{ info }}
		</v-snackbar>
		<v-snackbar v-model="hasSuccess" color="green" :top="isLG" :bottom="!isLG">
			<template v-slot:action="{ attrs }">
				<v-btn
					color="white"
					icon
					v-bind="attrs"
					@click="success = null">
					<v-icon>
						mdi-close
					</v-icon>
				</v-btn>
			</template>
			{{ success }}
		</v-snackbar>
		<v-snackbar v-model="hasError" color="red" :top="isLG" :bottom="!isLG">
			<template v-slot:action="{ attrs }">
				<v-btn
					color="white"
					icon
					v-bind="attrs"
					@click="error = null"
				>
					<v-icon>
						mdi-close
					</v-icon>
				</v-btn>
			</template>
			{{ error }}
		</v-snackbar>

		<proc-dialog :params="childProcDialog"
					 @click="onChildClick"
					 @open="onChildOpen"
					 @close="onChildProcClose"/>
		<confirm-dialog :params="confirmDialog" @yes="onConfirm"/>
		<msg-dialog :params="msgDialog" @yes="onAfterMsg"/>
		<delete-dialog :params="deleteDialog" @yes="onDelete(true)"/>
		<proc-params-dialog :params="paramsDialog"/>
		<proc-calendar-dialog :params="calendarDialog" @set="onDateSet"/>
		<proc-report-dialog :params="reportDialog"/>
		<proc-item-menu :params="itemMenu"/>
		<proc-filter-dialog :params="filterDialog" @apply="onFilterApplied"/>

		<input type="file"
			   class="proc__upload-input"
			   ref="upload-input"
			   @change="onUploadSelected"/>
	</div>
</template>

<script>
import Vue from "vue";
import ProcTable from "@/components/elements/proc-table";
import ProcTree from "@/components/elements/proc-tree";
import ProcParamsDialog from "@/components/elements/proc-params-dialog";
import ProcDialog from "@/components/elements/proc-dialog";
import ProcCalendarDialog from "@/components/elements/proc-calendar-dialog";
import ProcReportDialog from "@/components/elements/proc-report-dialog";
import DeleteDialog from "@/components/elements/delete-dialog";
import ConfirmDialog from "@/components/elements/confirm-dialog";
import MsgDialog from "@/components/elements/msg-dialog";
import ProcBtns from "@/components/elements/proc-btns";
import ProcBtnsMobile from "@/components/elements/proc-btns-mobile";
import ProcItemMenu from "@/components/elements/proc-item-menu";
import ProcFilterDialog from "@/components/elements/proc-filter-dialog";
import vp from "@/mixins/vp";
import {nf, md5} from "@/utils/string";
import {date2Iso} from "@/utils/date";
import {crossDownload} from "@/utils/utils";
import {debounce} from "lodash";
import Config from "@/config";
import procBase from "@/components/elements/proc-base";

export default {
	name: "proc",
	mixins: [vp, procBase],
	props: {
		data: {}, // данные выборки, если уже зафетчены - иначе зафетчятся здесь в компоненте (см. fetchProc())
		id: {}, // ID выборки = procNameId
		formId: {}, // ID родительской формы (если есть)

		params: {	// начальные значения параметров для выборки
			type: Array,
			default: () => []	// todo из-за этого дефолтного назначения массив становится ОБЩИМ для всех выборок в форме! проверить, что это ок
		},
		page: {	// номер страницы в таблице (1, 2, ...)
			default: 1
		},
		hideParams: {
			type: Boolean,
			default: false
		},
		hideButtons: {
			type: Boolean,
			default: false
		},
		readonly: {
			type: Boolean,
			default: false
		},
		withHeader: {
			type: Boolean,
			default: false
		},
		withOpen: {
			type: Boolean,
			default: false
		},
		minimized: {
			type: Boolean,
			default: false
		},
		name: {},
	},
	components: {
		ProcParamsDialog,
		ProcDialog,
		ProcTable,
		ProcTree,
		ProcCalendarDialog,
		ProcReportDialog,
		DeleteDialog,
		ConfirmDialog,
		MsgDialog,
		ProcBtns,
		ProcBtnsMobile,
		ProcItemMenu,
		ProcFilterDialog,
	},
	data: () => ({
		isRefetchingCurrentItem: false,
		isEditing: false,
		isUpdating: false,
		isBtnAddLoading: false,
		isDisabled: false,
		withSearch: false,
		search: '',
		filterCounter: 0,
		pfbParams: null,

		deleteDialog: {
			isVisible: false,
			isLoading: false,
		},
		reportDialog: {
			isVisible: false,
			callback: null
		},
		calendarDialog: {
			isVisible: false,
			date: null,
			item: null,
			col: null,
			callback: null,
			withTime: null,
		},
		filterDialog: {
			isVisible: false,
			title: null,
			headers: null,
			callback: null
		},
		itemMenu: {
			isVisible: false,
			x: 0,
			y: 0,
			callback: null,
			noUpdate: false,
			noDelete: false,
			noRefresh: false,
			withReport: false,
		},

		tableWidth: 400,
		contentHeight: 400,
		canRefresh: false,
		isPageRendered: false,
		isTreeView: false,

		pageIndex: 1,
		oldItem: null,

		validationQueue: [],

		/*proc: null,
		currentItem: null,
		currentItemIndex: null,
		buttons: null,
		*/
	}),
	computed: {
		title() {
			if ( this.isLG ) return this.meta?.CAPTION || this.name || "Загрузка...";
			return this.isLoading ? "Загрузка..." : this.meta?.CAPTION || this.name || "Загрузка...";
		},
		withMobileHeader() {
			return this.withHeader && !this.isLG;
		},
		paramsText: {
			get() {
				// только параметры с названием
				return this.proc?.paramSet?.filter(param => !!param.PARAMCAPTION).map(param => {
					//const value = typeof param.VALUE !== "undefined" ? param.VALUE : param.DEFAULTVLS;
					let displayValue = param.VALUE;
					const p = this.params?.find(el => el.INPARAMNAME === param.INPARAMNAME);
					if (p) displayValue = p.VALUE;

					if (param.ID_PROCNAMESOURCE) {
						//console.log("PARAM dict value", this.itemDict(param).find(el => Number(el.value) === Number(value)));
						displayValue = this.itemDict(param).find(el => Number(el.value) === Number(displayValue))?.text;
					} else if (param.PRMFORMAT === Config.TYPE_FORMAT_CHECKBOX) {
						//console.log("EL", param);
						if (Number(displayValue) === 1) displayValue = "Да";
						else if (Number(displayValue) === 0) displayValue = "Нет";
					}
					//else displayValue = param.VALUE;

					return "{" + param.PARAMCAPTION + ": " + displayValue + "}";
				}).join(" и ");
			},
			set(v) {
				// не меняем текст с параметрами
			}
		},
		canCreate() {
			//return !!this.meta?.INS_SQL && !!this.meta?.IS_INSERT;
			return !!this.meta?.IS_INSERT;
		},
		canUpdate() {
			//return !!this.meta?.UPD_SQL && !!this.meta?.IS_UPDATE && !!this.currentItem;
			return !!this.meta?.IS_UPDATE && !!this.currentItem;
		},
		canDelete() {
			//return !!this.meta?.DEL_SQL && !!this.meta?.IS_DELETE && !!this.currentItem;
			return !!this.meta?.IS_DELETE && !!this.currentItem;
		},
		canRefreshItem() {
			return !!this.meta?.REF_SQL && !!this.currentItem;
		},
		isReadonly() {
			//return this.readonly || !this.meta?.UPD_SQL || !this.meta?.IS_UPDATE;
			return this.readonly || !this.meta?.IS_UPDATE;
		},
		isPageReady() {
			return !!this.proc && (this.isTreeView || this.isPageRendered);
		},
		isCard() {
			return !!this.meta?.ID_PROCNAME_CARD;
		},
		withReport() {
			return this.meta?.IS_REPBUTTON;
		},
		canReport() {
			return !!this.currentItem;
		},
		hasOnNewRecord() {
			return this.headers?.some(el => !!el.ONNEWRECORD);
		},
		PER_PAGE() {
			return Config.ITEMS_PER_PAGE;
		},
		shouldEnterEditMode() {
			// найдем выборку в зарегистрированных выборках формы
			const proc = this.formProcs    // берем все текущие выборки
				.find(el => el.formId === this.formId && el.procNameId === this.procNameId);
			return proc?.shouldEnterEditMode;
		},
		resizeCounter() {
			return this.$store.state.resizeCounter;
		},
		hasGlobalEditedItem() {
			return false;//!!this.$store.state.editedItem;
		},
		hasGlobalEditedItemOfAnotherProc() {
			return false;//!!this.$store.state.editedItem && this.$store.state.editedItem !== this.currentItem;
		},
		componentClass() {
			const c = [];
			if (this.isDisabled) c.push("--disabled");
			if (this.withSearch) c.push("--search");
			if (!this.areAllPFBParamsSet) c.push("--unready");
			if (this.withMobileHeader) c.push("--header");
			if (!this.hideButtons) c.push("--btns");
			if (this.minimized) c.push("--minimized");
			return c.length ? c : null;
		},
		hasFilters() {
			return this.headers?.some(el => !!el.search||!!el._sort);
		},
		hasParams() {
			return this.proc?.paramSet?.some(param => !!param.PARAMCAPTION);
		}
	},
	watch: {
		// вариант открытия 1 - выборка открывается с уже зафетченными данными
		"data.procNameId": {
			immediate: true,
			handler() {
				if (this.data) {
					this.applyProc(this.data, true);
				}
			}
		},
		// вариант открытия 2 - выборка открывается по id (обычно внутри формы или диалога-селектора) - нужно зафетчить данные
		id: {
			immediate: true,
			async handler(val, old) {
				if (val !== old && this.id && !this.data) {
					//console.group("Proc ["+ val+"] initing...");
					let canLoadImmediatelly = false;
					if (this.ui?.isChild) {
						//console.log("This is a child proc, checking parent procs are ready...");
						if (this.areAllParentProcsLoaded) {
							//console.log("Yes, parents are loaded, so do we!");
							canLoadImmediatelly = true;
						}
					} else {
						//await this.awaitParentProcsLoaded();
						//console.log("This is a parent proc, start loading!");
						canLoadImmediatelly = true;
					}
					if (canLoadImmediatelly) {
						this.proc = null;	// важно сбросить поступившие ранее данные из бэка
						this.applyProcParams(!!old);	// важно применить родительские параметры, !!old - при чтении другой выборки - предварительно очищаем параметры
						//if (this.procNameId === -1098414) console.log("!!Proc [" + this.formId + "." + this.procNameId + "] calling fetchProc() 1");
						this.fetchProc(true);
					}
					//console.groupEnd();
				}
			}
		},
		shouldEnterEditMode(val, old) {
			if (val) {
				//console.group("shouldEnterEditMode [" + this.formId + "." + this.procNameId+"]");
				//console.log("Current item:", this.currentItem);
				if (this.currentItem) this.onEdit();
				const proc = this.formProcs    // берем все текущие выборки
					.find(el => el.formId === this.formId && el.procNameId === this.procNameId);
				if (proc) proc.shouldEnterEditMode = false;
				//console.groupEnd();
			}
		},
		currentItem(val, old) {
			this.paramsDialog.item = this.currentItem;
			this.oldItem = {...this.currentItem};	// a copy to restore values from on cancel editing
			//console.log("OLD ITEM SET", this.oldItem);

			if (this.isEditing) {
				// выходим из режима редактирования при смене currentItem
				this.isEditing = false;
			}

			// обновляем инфу для мира
			this.registerProc();
		},
		page: {
			immediate: true,
			handler() {
				// todo не работает?
				this.pageIndex = this.page;
			}
		},
		isChildWithoutPFBParams(val, old) {
			// при обновлении этой переменной - обновляем инфу для мира - todo описать, почему обновляемся здесь
			if (val && !old) {
				//console.log("registerProc in isChildWithoutPFBParams");
				this.registerProc();
			}
		},
		resizeCounter() {
			this.onResize();
		},
		"validationQueue.length"() {
			//console.log("Validation queue changed", this.validationQueue);
			this.processValidationQueueDebounced();
		},
		"$store.state.procIdsWithRefreshItem"(val, old) {
			//console.log("[" + this.procNameId + "] WATCH procIdsWithRefreshItem:", val);
			// поступила команда обновить текущий элемент выборки?
			if (val?.includes(this.procNameId) && (this.currentItem || this.multiselectedItems?.length)) {
				//console.log("\trefetching items!");
				this.refetchCurrentItem();

				// remove proc id from refreshed no immediately as there could be several same procs on screen
				setTimeout(() => {
					val.splice(val.findIndex(id => id === this.procNameId), 1);
				}, 150);
			}
		},
		"$store.state.procIdsWithRefresh"(val, old) {
			//console.log("[" + this.procNameId + "] WATCH procIdsWithRefresh:", val);
			// поступила команда обновить текущую выборку?
			if (val?.includes(this.procNameId)) {
				//console.log("\trefetching proc!");
				this.fetchProc();

				// remove proc id from refreshed no immediately as there could be several same procs on screen
				setTimeout(() => {
					val.splice(val.findIndex(id => id === this.procNameId), 1);
				}, 150);

			}
		},
		/*'$store.state.pageRoutes': {
			deep: true,
			handler: debounce(function (to, old) {
				const pr = this.$store.state.pageRoutes.find(el => Number(el.route.params?.id) === Number(to.params?.id));
				if ( !pr ) {
					this.$router.push({name: "home"});
				}
			}, 300)
		}*/
	},
	methods: {
		onClickParams() {
			this.paramsDialog.params = this.params;
			this.paramsDialog.paramSet = this.proc?.paramSet;
			this.paramsDialog.dicts = this.proc?.dicts;
			this.paramsDialog.isVisible = true;
			this.paramsDialog.callback = async () => {
				//console.log("params updated", this.params[2]?.VALUE);
				this.fetchProc();
			};
		},
		onAdd() {
			this.currentItem = null;
			this.multiselectedItems = [];
			this.oldItem = null;
			this.pageIndex = 1;

			// try to scroll table up
			const content = this.$refs['proc__content'];
			const wrapper = content?.querySelector('.v-data-table__wrapper');
			wrapper?.scroll(0, 0);

			if (this.isCard) {
				// открываем форму-карточку для нового элемента

				// 1. сделать запрос к SYS$GET_CARDPARAMS(:ID_PROCNAME = this.procNameId)
				this.fetchCardParams(this.procNameId).then((res) => {
					// 2. инициализировать параметры для карточки из (1) всех входных параметров выборки, (2) из кликнутого элемента
					const params = [];
					// map parent item
					if (res?.page?.mapping) {
						res.page.mapping?.forEach(p => {
							// здесь PARAMTYPE, а в SelectorController - PARAM_TYPE))
							const type = p.PARAMTYPE.trim().toLowerCase();

							if (type === "поле") {
								params.push({
									INPARAMNAME: p.PARAMNAME,
									VALUE: this.currentItem[p.SOURCE_NAME]
								});
							} else if (type === "параметр") {
								// ищем в параметрах выборки
								//console.log("this.params", this.params);
								let param = this.params.find(el => el.INPARAMNAME === p.SOURCENAME);
								// ищем в PFB-параметрах
								//console.log("this.pfbParams", this.pfbParams);
								if (!param) param = this.pfbParams?.find(el => el.INPARAMNAME === p.SOURCENAME);
								if (param) params.push({
									INPARAMNAME: p.PARAMNAME,
									VALUE: param?.VALUE
								});
							} else if (type === "значение") {
								params.push({
									INPARAMNAME: p.PARAMNAME,
									VALUE: p.DEFAULT_VALUE
								});
							}
						});
					}

					// 3. сделать запрос начальных значений свойств нового элемента
					// перенесен в карточку (диалог)
					//this.fetchOnNewRecord(params).then(initialValues => action(initialValues));

					//console.log("PARAMS TO CARD", params);

					this.childProcDialog.params = params;
					this.childProcDialog.item = null;
					this.childProcDialog.col = null;
					this.childProcDialog.id = this.meta?.ID_PROCNAME_CARD;	// это ID выборки DS на карточке
					this.childProcDialog.formId = this.meta?.ID_FORMNAME_AS_CARD;
					this.childProcDialog.parentId = this.procNameId;
					this.childProcDialog.hideButtons = true;
					this.childProcDialog.isCard = true;
					this.childProcDialog.btn = null;
					this.childProcDialog.readonly = false;
					this.childProcDialog.withOpen = true;
					this.childProcDialog.isVisible = true;
					this.childProcDialog.callback = () => {
						//console.group("CALLBACK!");

						// смотрим, создан ли в открываемой выборке (DS) новый элемент?
						const proc = this.formProcs.find(el => el.formId === this.childProcDialog.formId && el.procNameId === this.childProcDialog.id);
						//console.log("PROC!", proc);
						if (proc && proc.item) {
							//console.log("ITEM!", proc.item);
							if (proc.item[proc.meta.KEYFIELD]) {
								// да - ставим его текущим в нашей выборке
								//console.log("KEYFIELD!", proc.item[proc.meta.KEYFIELD]);
								const item = this.currentItem = proc.item;
								this.currentItemIndex = 0;
								this.items.unshift(item);

								// refresh current new item (as client asked)
								this.refetchCurrentItem();
							}
						}
						//console.groupEnd();

						this.childProcDialog.callback = null;
						this.childProcDialog.isCard = false;
					};
				});
			} else {
				const action = (initialValues = null) => {
					// small pause, as currentItem will be nullified while pageIndex changed
					setTimeout(() => {
						const item = this.currentItem = this.newItem(initialValues);
						this.currentItemIndex = 0;
						this.items.unshift(item);
						this.$store.state.editedItem = item;
						// small pause to allow currentItem being set
						setTimeout(() => {
							this.oldItem = null; // важно!
							this.isEditing = true;
						}, 50);
					}, 50);
				};

				if (this.hasOnNewRecord) {
					this.fetchOnNewRecord(this.params).then(initialValues => action(initialValues));
				} else action();
			}
		},
		onEdit() {
			this.isEditing = true;
			this.$store.state.editedItem = this.currentItem;
		},
		onCancelEdit() {
			this.isEditing = false;
			this.$store.state.editedItem = null;

			if (this.currentItem?.isNew) {
				// was a new item - just remove it
				this.items.splice(0, 1);
				this.currentItem = null;
			} else {
				const index = this.items.findIndex(el => el === this.currentItem);
				if (index >= 0) {
					if (this.oldItem) {
						if (md5(this.currentItem) !== md5(this.oldItem)) {
							// restore current item values from old one
							this.items.splice(index, 1, {...this.oldItem});
							this.oldItem = null;
							this.currentItem = this.items[index];
						}
					}
				}
			}
		},
		onSave() {
			/*moved to backend this.$store.dispatch("log", {
				ID_OBJECT: this.procNameId,
				ID_KEYFIELD: this.currentItem[this.meta.KEYFIELD],
				ID_WEBLOGTYPE: this.currentItem[this.meta.KEYFIELD] ? Config.ID_WEBLOGTYPE_UPDATE : Config.ID_WEBLOGTYPE_INSERT,
			});*/

			this.update(this.currentItem).then(() => {
				this.isEditing = false;
			});
		},
		onDelete(isConfirmed) {
			if (!this.currentItem) return;
			if (typeof isConfirmed !== "boolean" || !isConfirmed) {
				this.deleteDialog.isVisible = true;
				return;
			}

			/*moved to backend this.$store.dispatch("log", {
				ID_OBJECT: this.procNameId,
				ID_KEYFIELD: this.currentItem[this.meta.KEYFIELD],
				ID_WEBLOGTYPE: Config.ID_WEBLOGTYPE_DELETE,
			});*/

			//const itemIndex = (this.pageIndex - 1) * this.PER_PAGE + this.currentItemIndex;
			const itemIndex = this.items.findIndex(el => el === this.currentItem);

			/*console.log("this.currentItemIndex", this.currentItemIndex);
			console.log("this.pageIndex", this.pageIndex);
			console.log("itemIndex", itemIndex);
			return;*/
			//this.isLoading = true;
			this.deleteDialog.isLoading = true;
			this.$store.dispatch("del", {
				action: "ItemController",
				params: {
					formId: this.formId,
					procNameId: this.procNameId,
					item: this.currentItem,
					params: this.params
				}
			})
				.then(res => {
					//console.log("RES", res);

					this.items.splice(itemIndex, 1);
					this.currentItem = null;
					this.currentItemIndex = null;
					this.deleteDialog.isVisible = false;

					this.refreshProcsAfterItemUpdate(res);
				})
				.catch(err => {
					console.error("ERROR", err);
					this.error = err.error;
				})
				.finally(() => {
					//this.isLoading = false;
					this.deleteDialog.isLoading = false;
				});
		},
		onCancel() {
			this.info = "Отмена! Что необходимо отменить?";
		},
		onClickFilter() {
			this.filterDialog.title = this.title;
			this.filterDialog.headers = this.headers;
			this.filterDialog.isVisible = true;
		},
		onFilterApplied() {
			this.filterCounter++;	// run table filtering
		},
		onResize() {
			if (this.isLG) {
				// не меняем размер в режиме редактирования в десктопе
				if (this.isEditing) return;
				this.calcTableSize();
			} else {
				this.calcTableSize();
			}
		},
		onItemMenu(event, item, index) {
			let timeout = 0;
			if (this.itemMenu.isVisible) {
				this.itemMenu.isVisible = false;
				timeout = 150;
			}

			setTimeout(() => {
				this.itemMenu.x = event.clientX;
				this.itemMenu.y = event.clientY + 16;
				this.itemMenu.isVisible = true;
				this.itemMenu.noUpdate = !this.canUpdate || this.hasGlobalEditedItemOfAnotherProc;
				this.itemMenu.noDelete = !this.canDelete || this.hasGlobalEditedItem;
				this.itemMenu.noRefresh = !this.canRefreshItem || this.hasGlobalEditedItem;
				this.itemMenu.withReport = this.withReport;
				this.itemMenu.noReport = !this.canReport || this.hasGlobalEditedItem;
				this.itemMenu.callback = (event) => {
					if (event === "edit") this.onOpen()
					else if (event === "delete") this.onDelete();
					else if (event === "refresh") this.refetchCurrentItem();
					else if (event === "report") this.onReport();
				};
			}, timeout);
		},
		/**
		 * Вызывается на клик по элементу в таблице данной выборки.
		 */
		onClick(item, index) {
			if (index !== this.currentItemIndex) {
				this.currentItem = item;
				this.currentItemIndex = index;
			}
			this.$emit("click", item);
		},
		/**
		 * Вызывается на даблклик по элементу в таблице данной выборки.
		 */
		onOpen() {
			if (this.withOpen) this.$emit("open", this.currentItem);
			else if (this.isCard) {
				this.openCard();
			}
			else if (this.canUpdate) {
				// открываем редактирование
				this.onEdit();
			}
		},
		onPage(pageIndex) {
			this.pageIndex = pageIndex;
			//if ( this.currentItem ) this.update(this.currentItem);
			this.$emit("page", pageIndex);
		},
		onDate(item, col, callback) {
			// proc table clicked an item with date picker
			this.calendarDialog.item = item;
			this.calendarDialog.col = col;
			this.calendarDialog.date = item[col.value];//?.substr(0, 10);
			this.calendarDialog.withTime = this.isColDateWithTime(col);
			this.calendarDialog.isVisible = true;
			this.calendarDialog.callback = callback;
		},
		onDateSet() {
			// child dialog clicked an item
			this.calendarDialog.isVisible = false;
			const col = this.calendarDialog.col;
			const item = this.calendarDialog.item;
			//console.log("DATE", this.calendarDialog.date);
			item[col.PROCPARAM] = this.calendarDialog.date;///date2Iso(this.calendarDialog.date);
			//this.update(item);
		},
		/**
		 * В выборке при редактировании выбрана колонка с селектором.
		 * @param item
		 * @param col
		 */
		onSelector(item, col, callback) {
			//console.log("CHILD PROC", item, col);

			// fetch selector mapping
			this.$store.dispatch("get", {
				action: "SelectorController",
				params: {
					id: col.ID_PROCPARAM,
				},
			})
				.then(res => {
					const params = [];

					// map parent item
					if (res?.page?.mapping) {
						res.page.mapping?.forEach(p => {
							const type = p.PARAM_TYPE.trim().toLowerCase();

							if (type === "поле") {
								params.push({
									INPARAMNAME: p.PARAMNAME,
									VALUE: item[p.SOURCE_NAME]
								});
							} else if (type === "параметр") {
								params.push({
									INPARAMNAME: p.PARAMNAME,
									VALUE: this.params.find(el => el.INPARAMNAME === p.SOURCE_NAME)?.VALUE
								});
							} else if (type === "значение") {
								params.push({
									INPARAMNAME: p.PARAMNAME,
									VALUE: p.DEFAULT_VALUE
								});
							}
						});
					}

					this.childProcDialog.params = params;
					this.childProcDialog.item = item;
					this.childProcDialog.col = col;
					this.childProcDialog.id = col.ID_SELECTION_LKP;
					this.childProcDialog.formId = null;
					this.childProcDialog.parentId = null;
					this.childProcDialog.hideButtons = true;
					this.childProcDialog.withHeader = true;
					this.childProcDialog.isCard = false;
					this.childProcDialog.btn = null;
					this.childProcDialog.readonly = true;
					this.childProcDialog.withOpen = true;
					this.childProcDialog.isVisible = true;
					this.childProcDialog.callback = callback;

					//console.log("PARAMS TO CHILD proc", this.childProcDialog);
				})
				.catch(err => {
					console.error("ERROR", err);
					this.error = err.error;
				})
				.finally(() => {
				});
		},
		/**
		 * Вызывается при закрытии окна дочерней выборки.
		 */
		onClose() {
			this.$emit("close");
		},
		onSearch() {
			this.withSearch = !this.withSearch;
			this.onResize();
		},
		onReport() {
			// в параметры отчетов передаются ТОЛЬКО св-ва выбранного элемента (параметры выборе НЕ передавать!)
			this.reportDialog.params = [];
			Object.keys(this.currentItem).forEach(key => {
				this.reportDialog.params.push({
					INPARAMNAME: key,
					VALUE: this.currentItem[key],
				});
			});
			this.reportDialog.formId = this.formId;	// может быть undefined
			this.reportDialog.procNameId = this.procNameId;
			this.reportDialog.isVisible = true;
		},
		onValidate(item, col) {
			if (this.isValidatonDisabled) {
				console.log("Validation skipped for " + col.PROCPARAM);
				return;
			}
			if (this.validationQueue.findIndex(el => el.col === col) === -1) {
				//console.log("Col " + col.PROCPARAM + " appends validation queue");
				this.validationQueue.push({col, item, time: new Date()});
			}
		},
		/**
		 * Открывает форму-карточку для выбранного элемента.
		 * @param item
		 */
		openCard() {
			const item = this.currentItem;

			// 1. сделать запрос к SYS$GET_CARDPARAMS(:ID_PROCNAME = this.procNameId)
			this.fetchCardParams(this.procNameId).then((res) => {

				// 2. инициализировать параметры для карточки из (1) всех входных параметров выборки, (2) из кликнутого элемента
				const params = [];
				// map parent item
				if (res?.page?.mapping) {
					res.page.mapping?.forEach(p => {
						// здесь PARAMTYPE, а в SelectorController - PARAM_TYPE))
						const type = p.PARAMTYPE.trim().toLowerCase();

						if (type === "поле") {
							params.push({
								INPARAMNAME: p.PARAMNAME,
								VALUE: this.currentItem[p.SOURCE_NAME]
							});
						} else if (type === "параметр") {
							// ищем в параметрах выборки
							//console.log("this.params", this.params);
							let param = this.params.find(el => el.INPARAMNAME === p.SOURCENAME);
							// ищем в PFB-параметрах
							//console.log("this.pfbParams", this.pfbParams);
							if (!param) param = this.pfbParams?.find(el => el.INPARAMNAME === p.SOURCENAME);
							if (param) params.push({
								INPARAMNAME: p.PARAMNAME,
								VALUE: param?.VALUE
							});
						} else if (type === "значение") {
							params.push({
								INPARAMNAME: p.PARAMNAME,
								VALUE: p.DEFAULT_VALUE
							});
						}
					});
				}
				//console.log("PARAMS TO CARD", params);

				this.childProcDialog.params = params;
				this.childProcDialog.item = item;
				this.childProcDialog.col = null;
				this.childProcDialog.id = this.meta?.ID_PROCNAME_CARD;
				this.childProcDialog.formId = this.meta?.ID_FORMNAME_AS_CARD;
				this.childProcDialog.parentId = this.procNameId;
				this.childProcDialog.hideButtons = true;
				this.childProcDialog.withHeader = false;
				this.childProcDialog.isCard = true;
				this.childProcDialog.btn = null;
				this.childProcDialog.readonly = false;
				this.childProcDialog.withOpen = true;
				this.childProcDialog.isVisible = true;
			});
		},
		isColDateWithTime(col) {
			if (!col) return false;
			return col.FIELD_TYPE_EX?.match(/^timestamp/);
		},
		processValidationQueueDebounced: debounce(function () {
			this.validationQueue.forEach(el => {
				const item = el.item;
				const col = el.col;

				const index = this.validationQueue.findIndex(el => el.col === col);
				if (index >= 0) this.validationQueue.splice(index, 1);

				console.group("Validating col " + col.PROCPARAM, item[col.PROCPARAM]);
				return this.$store.dispatch("post", {
					action: "ProcOnValidateController",
					params: {
						id: this.procNameId,
						item,
						col,
						params: this.params
					},
				})
					.then(res => {
						const values = res?.page?.values;
						console.log("\tValidated " + col.PROCPARAM + ":", values);
						if (values) {
							Object.keys(values).forEach(key => item[key] = values[key]);
							this.tableFooterRefreshCounter++;
						}
					})
					.catch(err => {
						console.error("ERROR", err);
						this.error = err.error;
					})
					.finally(() => {
						console.groupEnd();
					});
			});
		}, Config.INPUT_DEBOUNCE_TIMEOUT),
		calcTableSize() {
			if (this.isLG) this.isPageRendered = false;
			setTimeout(() => {
				if (this.$refs.proc__content) {
					this.tableWidth = (this.$refs.proc__content?.offsetWidth || 0) - 1;
					this.contentHeight = this.$refs.proc__content?.offsetHeight;// - minus;

					//console.log("table: " + this.title);
					//console.log("\tsize: " + this.tableWidth + "x"+this.contentHeight);

					this.isPageRendered = true;
				}
			}, 150);
		},
		/*onUpdateDebounced: debounce(function (item, index) {
			this.update(item, index);
		}, Config.INPUT_DEBOUNCE_TIMEOUT_LONG),*/
		/**
		 * @deprecated
		 * @returns {Promise<unknown>}
		 */
		/*awaitParentProcsLoaded() {
			return new Promise((resolve, reject) => {
				if (!this.ui?.isChild ) {
					resolve();
					return;
				}

				const checkLoaded = () => {
					console.log(new Date().valueOf() + " Proc ["+this.procNameId + "] awaits parents "+this.ui.parentProcIds?.join(","));
					if ( this.ui.parentProcIds?.every(id => {
						const proc = this.allParentProcs.find(el=>el.procNameId === id);
						return !!proc && !proc.isLoading;
					}) ) {
						console.group(new Date().valueOf() + " Proc ["+this.procNameId + "] has parents "+this.ui.parentProcIds?.join(",")+" loaded:");
						this.ui.parentProcIds.forEach(id=>{
							const proc = this.allParentProcs.find(el=>el.procNameId === id);
							console.log(id+ ": "+proc.procNameId + ", "+proc.items?.length);
						});
						console.groupEnd();
						resolve();
						return;
					}
					setTimeout(checkLoaded, 300);
				}
				checkLoaded();
			});
		},*/
		/*initParams() {
			//console.log("initParams", this.proc);
			//this.procParams = this.proc?.paramSet?.filter(el => !!el.PARAMCAPTION);
			this.procParams = this.proc?.paramSet;
			this.paramsDialog.dicts = this.proc?.dicts;
		},*/
		newItem(initialValues = null) {
			const item = {
				isNew: true,
				//...initialValues	todo doesn't work...
			};
			this.headers?.forEach(col => {
				if (initialValues && typeof initialValues[col.PROCPARAM] !== "undefined") Vue.set(item, col.PROCPARAM, initialValues[col.PROCPARAM]);
				else if (!!col.CHECKBOX) Vue.set(item, col.PROCPARAM, 0);
				else Vue.set(item, col.PROCPARAM, undefined);	// undefined is important!
			});

			return item;
		},
		update(item) {
			return new Promise((resolve, reject) => {
				if (md5(item) === md5(this.oldItem)) {
					resolve();
					return;
				}

				this.isUpdating = true;
				this.$store.dispatch(item.isNew ? "post" : "put", {
					action: "ItemController",
					params: {
						formId: this.formId,
						procNameId: this.procNameId,
						item,
						oldItem: this.oldItem,
						params: this.params
					},
				})
					.then(res => {
						//console.log("RES", res);
						//this.$store.state.page = res.page;

						const wasNew = item.isNew;
						this.$set(item, "isNew", false);

						if (res?.page?.item) {
							// refresh current item
							//console.log("CURRENT ITEM BEFORE", this.currentItem);
							this.replaceCurrentItem(res.page.item);
							//console.log("Item refreshed");
							//console.log("CURRENT ITEM AFTER", this.currentItem);
						}
						if (!this.refreshProcsAfterItemUpdate(res) && wasNew) {
							// был создан новый элемент и не было рефреша - нужно зареврешить дочерние выборки через переустановку элемента
							const ci = this.currentItem;
							this.currentItem = null;
							setTimeout(() => {
								this.currentItem = ci;
							}, 50);
						}

						if ( wasNew ) this.success = "Элемент добавлен";
						else this.success = "Изменения сохранены";
						// the prev item is now the old item
						//this.oldItem = {...item};

						resolve();
					})
					.catch(err => {
						console.error("ERROR", err);
						this.error = err.error;
						reject(err);
					})
					.finally(() => {
						this.isUpdating = false;
					});
			});
		},
		/**
		 * Обновляет эту или внешние выборки в зависимости от настроек выборки/элемента.
		 * @param res
		 */
		refreshProcsAfterItemUpdate(res) {
			let hadRefresh = false;
			// refresh the whole page?
			if (Number(this.meta.IS_REOPEN_AFTERPOST) === 1) {
				// refresh current proc
				// todo reopen proc or the while page?
				this.fetchProc();
				hadRefresh = true;
			} else {
				//console.group("Refreshing outter procs", res.page?.refreshes);

				// требуется ли перечитка внешних выборок (в родительской форме)
				res.page?.refreshes?.forEach(refresh => {
					if (refresh?.EVENT_DET === Config.BTN_REFRESH_TYPE_ITEM) {
						//console.log("Config.BTN_REFRESH_TYPE_ITEM!", refresh);
						//if ( refresh.ID_PROCNAME_DET === this.procNameId) withKeepDisabled = true;
						this.$store.state.procIdsWithRefreshItem.push(refresh.ID_PROCNAME_DET);
						//console.log("procIdsWithRefreshItem", this.$store.state.procIdsWithRefreshItem);
						hadRefresh = true;
					} else if (refresh?.EVENT_DET === Config.BTN_REFRESH_TYPE_PROC) {
						//console.log("Config.BTN_REFRESH_TYPE_PROC!", refresh);
						this.$store.state.procIdsWithRefresh.push(refresh.ID_PROCNAME_DET);
						//console.log("procIdsWithRefresh", this.$store.state.procIdsWithRefresh);
						hadRefresh = true;
					}
				});
				//console.groupEnd();
			}

			return hadRefresh;
		},
		fetchProcReal() {
			this.unregisterProc();
			this.clearData();
			/*if (this.procNameId === -1098414) {
				console.log("Proc [" + this.procNameId + "] fetching", this.params);
				this.pfbParams?.forEach(el => {
					console.log("PFB param: " + el.INPARAMNAME + ": " + el.VALUE);
				});
			}*/

			// важно! перед отправкой на сервер убираем реактивность сбором параметров в локальный массив - иначе (почему-то) обновляется params и происходит перечитка выборки:
			//console.group("Proc " + this.formId + "." + this.procNameId + " REQUEST params:");
			const params = this.params.map(param => {
				//console.log("'" + param.INPARAMNAME + "' = " + param.VALUE);
				return {
					INPARAMNAME: param.INPARAMNAME,
					VALUE: param.VALUE
				}
			});
			//console.groupEnd();

			//console.log(new Date().valueOf() + " Proc ["+this.procNameId + "] loading...");
			this.isLoading = true;
			this.canRefresh = false;
			this.$store.dispatch("get", {
				action: "ProcController",
				params: {
					id: this.procNameId,
					formId: this.formId,
					params,
				},
			})
				.then(res => {
					this.applyProc(res.page);
					this.$emit("loaded", this.proc);
					//console.log(new Date().valueOf() + " Proc ["+this.procNameId + "] loaded!", this.proc);
				})
				.catch(err => {
					console.error("ERROR", err);
					this.error = err.error;
				})
				.finally(() => {
					this.isLoading = false;
					this.calcTableSize();
				});
		},
		/**
		 * Запрашивает св-ва по-умолчанию для новых элементов.
		 * @param params
		 * @returns {Promise<unknown>}
		 */
		fetchOnNewRecord(params) {
			this.isBtnAddLoading = true;
			return this.$store.dispatch("post", {
				action: "ProcOnNewItemController",
				params: {
					id: this.procNameId,
					params,
				},
			})
				.then(res => {
					return res?.page.values;
				})
				.catch(err => {
					console.error("ERROR", err);
					this.error = err.error;
				})
				.finally(() => {
					this.isBtnAddLoading = false;
				});
		},
		/**
		 * Запрос к SYS$GET_CARDPARAMS(:ID_PROCNAME = procNameId)
		 * @param procNameId
		 * @returns {Promise<unknown>}
		 */
		fetchCardParams(procNameId) {
			return this.$store.dispatch("get", {
				action: "CardController",
				params: {
					id: procNameId,
				},
			})
				.catch(err => {
					console.error("ERROR", err);
					this.error = err.error;
				})
				.finally(() => {
				});
		},
		itemDict(param) {
			//console.log("PARAM", param);
			const dict = this.proc?.dicts[param.ID_PROCNAMESOURCE];
			if (!dict) return [];
			return dict.map((el, index) => {
				return {
					value: el[param.PROCNAMESOURCEKEYFLD?.trim()],
					text: el[param.PROCNAMESOURCEFLD]
				};
			});
		},
		applyProc(data, withResetParams = false) {
			this.clearData();
			this.proc = null;	// this should not be cleared in clearData()
			if (!data) return;

			this.proc = data;
			//this.setGlobalAvailableCols();
			this.applyHeaders();
			this.applyProcParams(withResetParams);
			this.applyButtons();
			this.applyProcItems();
			this.canRefresh = true;
			this.isTreeView = this.meta?.IS_TREE;

			//console.log("PROC " + this.procNameId + " PBFs: " + this.headers.filter(el => el.PROCPARAM.match(/^PFB_/)).map(el => el.PROCPARAM));
		},
		applyProcParams(withResetParams = false) {

			//console.log("applyProcParams", withResetParams);

			if (withResetParams) {
				// очистить параметры
				this.params?.splice(0, this.params.length);
			}

			// first, apply paramSet
			//console.group("Proc " + this.procNameId + " applying paramSet:");
			this.proc?.paramSet?.forEach(param => {
				const p = this.params?.find(el => el.INPARAMNAME === param.INPARAMNAME);
				if (p) {
					if (typeof param.VALUE !== "undefined") {
						Vue.set(p, "VALUE", param.VALUE);
						//console.log("SET '" + p.INPARAMNAME + "': " + param.VALUE);
					}
				} else {
					this.params?.push(param);
					//console.log("PUSH '" + param.INPARAMNAME + ": " + param.VALUE);
				}
			});
			//console.groupEnd();

			// now, apply params
			if (!withResetParams) {
				//console.group("Proc " + this.procNameId + " applying params:");
				this.proc?.params?.filter(el => !el.INPARAMNAME.match(/^PFB_/)).forEach(param => {
					const p = this.params?.find(el => el.INPARAMNAME === param.INPARAMNAME);
					if (p) {
						if (typeof param.VALUE !== "undefined") {
							Vue.set(p, "VALUE", param.VALUE);
							//console.log("SET '" + p.INPARAMNAME + "': " + param.VALUE);
						}
					} else {
						this.params?.push(param);
						//console.log("PUSH '" + param.INPARAMNAME + ": " + param.VALUE);
					}
				});
				//console.groupEnd();
			}

			// now apply parent params for child proc
			//console.group("Proc " + this.procNameId + " applying PFB params:");
			this.initPFBParams();
			this.pfbParams?.forEach(param => {
				const p = this.params?.find(el => el.INPARAMNAME === param.INPARAMNAME);
				if (p) {
					if (typeof param.VALUE !== "undefined") {
						Vue.set(p, "VALUE", param.VALUE);
						//console.log("PFB SET '" + p.INPARAMNAME + "': " + param.VALUE);
					}
				} else {
					this.params?.push(param);
					//console.log("PFB PUSH '" + param.INPARAMNAME + ": " + param.VALUE);
				}
			});

			/*console.group("Proc "+this.procNameId+" final params:");
			this.params.forEach(param => {
				console.log("'"+param.INPARAMNAME + "' = "+param.VALUE);
			});
			console.groupEnd();*/
		},
		applyProcItems() {
			// копируем себе список и нумеруем элементы (todo нужна ли все еще нумерация?)
			this.items = this.proc?.items?.map((el, index) => ({...el, index})) || [];
			//if (this.procNameId === -1098414) console.log("[-1098414] applyProcItems(): " + this.proc?.items?.length);

			this.onResize();	  // needed to calc table height for new items
			//console.log("registerProc in applyProcItems");
			this.registerProc();

			// todo temp
			/*if ( this.procNameId ===-7844 ) {
				const index = this.items.findIndex(el=>el.SHORTNAME.match(/большевик/i));
				const item = this.items[index];
				console.log("Opening", item);
				setTimeout(() => {
					this.onClick(item, index);
					setTimeout(() => {
						this.onOpen(item);
					}, 150);
					/!*setTimeout(() => {
						this.onButton(this.buttons[0]);
					}, 150);*!/
				}, 150);
			}*/
			/*if (this.procNameId === -1052960) {
				setTimeout(() => {
					this.onClick(this.items[3], 3);
					setTimeout(() => {
						this.onOpen(this.items[3]);
					}, 150);
				}, 150);
			}*/
		},
	},
	mounted() {
		//if (this.isTouch) window.addEventListener("orientationchange", this.onResize);
		window.addEventListener("resize", this.onResize);
		this.onResize();
	},
	destroyed() {
		//if (this.isTouch) window.addEventListener("orientationchange", this.onResize);
		window.removeEventListener("resize", this.onResize);

		if (this.$store.state.editedItem && this.$store.state.editedItem === this.currentItem) this.$store.state.editedItem = null;
	}
}
</script>

<style lang="scss">

$header-height: 48px;

.proc {
	position: relative;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	padding: 0;
	width: 100%;
	height: 100%;
	background: $white;
	overflow: hidden;

	@include up($lg) {
		border: $border;
		padding: 4px;
	}

	&.--header {
		.proc__body {
			height: calc(100% - $header-height);
			@include up($lg) {
				height: calc(100% - 13px);
			}
		}
	}

	&.--btns {
		@include up($lg) {
			padding-top: 0;
		}
	}

	&__header {
		height: $header-height;
		padding: 0 .75rem 0 1.25rem;
		@include up($lg) {
			height: unset;
			padding: 0;
		}
	}

	&__body {
		height: 100%;
		display: flex;
		flex-direction: column;

		/*h3 {
			padding: 0.75rem 0.75rem 0.25rem 0.75rem;
			//background: $border-color;
		}*/
	}

	&__params {
		.v-text-field--outlined {
			border-radius: 0;

			fieldset {
				border-color: $border-color !important;
			}
		}

		.v-text-field--outlined.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot {
			min-height: 24px;
			padding: 0 4px;

			input {
				font-size: 13px;
				cursor: pointer;
				padding: 0 4px;
			}
		}
	}

	&__btns {
		/*position: fixed;
		z-index: $z-index-header;
		left: 0;
		top: 80px;
		width: 100%;*/
		height: $header-height;
		flex-grow: 0;
		display: flex;
		align-items: center;

		@include up($lg) {
			height: 32px;
		}

		&-content {
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: space-between;

			&-left {
				height: 40px;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				flex-grow: 1;
				padding-left: 1.25rem;
				overflow: hidden;

				@include up($lg) {
					height: 32px;
					padding-left: 0;
					overflow: unset;
				}
			}

			&-right {
				height: 40px;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				flex-grow: 0;
				white-space: nowrap;
				padding-right: calc(.75rem + 1px); //todo needs 1px to be verticall y aligned with table items menu btns - where is this 1px from? scroll? table border?

				@include up($lg) {
					height: 32px;
					padding-right: 0;
				}
			}
		}
	}

	&__content {
		//position: relative;
		//height: 100%;
		overflow: hidden;
	}

	&__upload-input {
		position: absolute;
		left: -1000px;
		top: 0;
		opacity: 0;
		width: 1px;
		height: 1px;
		overflow: hidden;
	}

	&.--unready {
		//background: $thead-bg-color;
		color: $border-color;
		justify-content: center;
		align-items: center;
	}

	&.--disabled {
		.proc__fade {
			pointer-events: all;
			background: rgba(255, 255, 255, 0.65);
		}
	}

	&.--minimized {
		.proc__btns-content-right {
			display: none;
		}
	}

	.proc__fade {
		position: absolute;
		z-index: 10;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		//opacity: 0.75;
		//backdrop-filter: blur(8px);
		display: flex;
		justify-content: center;
		align-items: center;
		pointer-events: none;
		@include transition();
	}
}
</style>
<template>
	<v-menu v-model="params.isVisible" offset-x right absolute
			:close-on-content-click="true"
			:z-index="9"
			:position-x="params.x"
			:position-y="params.y" content-class="proc-item-menu">

		<v-list dense class="proc-item-menu__list">
			<v-list-item :disabled="params.noUpdate" @click="onClick('edit')">
				<v-icon :disabled="params.noUpdate">mdi-pencil</v-icon>
				<v-list-item-title>Редактировать</v-list-item-title>
			</v-list-item>
			<v-list-item :disabled="params.noDelete" @click="onClick('delete')">
				<v-icon :disabled="params.noDelete">mdi-delete-outline</v-icon>
				<v-list-item-title>Удалить</v-list-item-title>
			</v-list-item>
			<v-list-item :disabled="params.noRefresh" @click="onClick('refresh')">
				<v-icon :disabled="params.noRefresh">mdi-refresh</v-icon>
				<v-list-item-title>Перечитать элемент</v-list-item-title>
			</v-list-item>
			<v-list-item v-if="params.withReport" :disabled="params.noReport" @click="onClick('report')">
				<v-icon :disabled="params.noReport">mdi-text-box-outline</v-icon>
				<v-list-item-title>Получить отчет</v-list-item-title>
			</v-list-item>
		</v-list>
	</v-menu>
</template>

<script>
import OnEscape from "@/mixins/on-escape";

export default {
	name: "proc-item-menu",
	mixins: [OnEscape],
	props: {
		params: {}
	},
	data: () => ({
		search: null,
	}),
	computed: {
	},
	methods: {
		close() {
			this.params.isVisible = false;
		},
		onClick(event) {
			this.$emit(event, this.params);
			if (this.params.callback) this.params.callback(event, this.params);
			this.params.callback = null;
			this.close();
		},
		onEscape() {
			this.close();
		},
	}
}
</script>

<style lang="scss">
.proc-item-menu {
	//z-index: $z-index__proc-item-menu;
	//.v-icon
}
</style>
<template>





	<!-- @deprecated -->





	<!-- proc-dialog-panels это форма, открываемая в диалоге - содержит набор панелей + (опционально) кнопки для сохранения данных в форме (это называется "карточкой") -->
	<!-- здесь обрабатываются запросы на сохранение карточек -->
	<!-- todo возможно стоит это объединить с ds -->
	<div class="d-flex flex-column proc-dialog-panels">
		<div class="proc-dialog-panels__header d-flex justify-space-between align-center flex-grow-0">
			<h3>{{ formTitle }}</h3>
			<v-btn icon @click="onClose">
				<v-icon>mdi-close</v-icon>
			</v-btn>
		</div>

		<div v-if="isLoading" class="proc-dialog-panels__loader">
			<v-progress-circular indeterminate
								 color="grey lighten-2"/>
			<p class="mt-8">Пожалуйста, подождите...</p>
		</div>
		<template v-else-if="data">
			<div v-if="withCardButtons" class="proc-dialog-panels__btns">
				<v-btn :loading="isUpdatingSave"
					   :disabled="isUpdatingSaveAndClose || !isChanged"
					   text title="Сохранить"
					   @click="onSave">
					<v-icon x-small>
						mdi-content-save
					</v-icon>
					Сохранить
				</v-btn>

				<v-btn :loading="isUpdatingSaveAndClose"
					   :disabled="isUpdatingSave || !isChanged"
					   text title="Сохранить и закрыть"
					   @click="onSaveAndClose">
					<v-icon x-small>
						mdi-content-save
					</v-icon>
					Сохранить и закрыть
				</v-btn>

				<v-btn text :disabled="isUpdatingSave || isUpdatingSaveAndClose"
					   @click="onClose" title="Отмена">
					<v-icon x-small>
						mdi-close
					</v-icon>
					Отмена
				</v-btn>
			</div>

			<!-- todo clear form controls on form change -->
			<panels :data="data" :dialog-params="dialogParams"/>
		</template>

		<v-snackbar v-model="hasError" color="red" top>
			<template v-slot:action="{ attrs }">
				<v-btn
					color="white"
					icon
					v-bind="attrs"
					@click="error = null">
					<v-icon>
						mdi-close
					</v-icon>
				</v-btn>
			</template>
			{{ error }}
		</v-snackbar>
	</div>

</template>

<script>
import {md5} from "@/utils/string";
import Config from "@/config";



// @deprecated !!!!!!!!!!!!!!!!!!!!!!!!



/**
 * @deprecated
 */
export default {
	name: "proc-dialog-panels",
	props: {
		dialogParams: {
			// это параметры из диалога
		},
	},
	components: {
		//Proc
	},
	data: () => ({
		data: null,
		isLoading: true,
		isUpdatingSave: false,
		isUpdatingSaveAndClose: false,
		initialHash: null,
		initialHashBlobs: null,

		error: null,
	}),
	computed: {
		hasError: {
			get() {
				return !!this.error;
			},
			set(v) {
				if (!v) this.error = null;
			}
		},
		meta() {
			return this.data?.meta;
		},
		formTitle() {
			return this.ui?.find(el => el.COMPNAME.match(/fmForm/))?.caption || 'Загрузка...';
		},
		ui() {
			return this.data?.ui;
		},
		cardDS() {
			// ищем ds-выборку по параметрам диалога
			return this.$store.state.formProcs.find(el => el.formId === this.dialogParams.formId && el.procNameId === this.dialogParams.id);
		},
		/**
		 * Возвращает элемент, редактируемый в карточке (при dialogParams.isCard===true)
		 * @returns {null}
		 */
		cardItem() {
			//if (!this.dialogParams.isCard) return null;
			//console.log("cardDS", this.cardDS);
			return this.cardDS?.item;
		},
		cardBlobs() {
			//if (!this.dialogParams.isCard) return null;
			return this.cardDS?.blobs;
		},
		withCardButtons() {
			return this.dialogParams?.isCard || this.ui?.find(el => el.COMPNAME?.match(/fmForm/i) && el.tag);
		},
		isChanged() {
			return (this.initialHash && this.initialHash !== md5(this.cardItem))
				|| (this.initialHashBlobs && this.initialHashBlobs !== md5(this.cardBlobs))
		}
	},
	watch: {
		"dialogParams.formId": {
			immediate: true,
			handler(val, old) {
				if (val && val !== old) {
					this.fetchForm();
				}
			}
		},
		cardItem() {
			if ( !this.initialHash ) this.initialHash = md5(this.cardItem);
		},
		cardBlobs() {
			if ( !this.initialHashBlobs ) this.initialHashBlobs = md5(this.cardBlobs);
		}
	},
	methods: {
		onClose() {
			this.$emit("close");
		},
		onSave() {
			this.isUpdatingSave = true;
			this.updateCardItem();
		},
		onSaveAndClose() {
			this.isUpdatingSaveAndClose = true;
			this.updateCardItem().then(() => this.onClose());
		},
		updateCardItem() {

			this.$store.dispatch("log", {
				ID_OBJECT: this.dialogParams.id,
				ID_KEYFIELD: this.cardItem[this.cardDS.meta.KEYFIELD],
				ID_WEBLOGTYPE: this.dialogParams.item ? Config.ID_WEBLOGTYPE_UPDATE : Config.ID_WEBLOGTYPE_INSERT,
			});

			return new Promise((resolve, reject) => {

				this.$store.dispatch(this.dialogParams.item ? "put" : "post", {
					action: "ItemController",
					params: {
						procNameId: this.dialogParams.id,
						item: this.cardItem,
						oldItem: this.dialogParams.item,
						blobs: this.cardBlobs,
						params: this.dialogParams?.params
					},
				})
					.then(res => {
						//console.log("RES", res);
						//console.log("PARAMS", this.dialogParams);

						this.initialHash = md5(this.cardItem);
						this.initialHashBlobs = md5(this.cardBlobs);

						// находим выборку в родительском окне (под диалогом) - та, из которой открыли диалог - опционально помечаем ее на refetch
						// здесь ищем без идентификатора формы - это ок по идее, так как, если таких выборок несколько - они все должны зареветчиться
						const parentProc = this.$store.state.formProcs.find(el => el.procNameId === this.dialogParams.parentId);

						if (this.dialogParams.item) {
							//console.log("this.dialogParams.item", this.dialogParams.item);
							//console.log("this.$store.state.formProcs", this.$store.state.formProcs);
							//console.log("this.dialogParams.parentId", this.dialogParams.parentId);
							// мы обновили существующий элемент
							if (parentProc) {
								//console.log("parentProc", parentProc);
								// mark parent proc to refresh current item/refresh in full
								if (parentProc.meta?.IS_REOPENAFTERCARD) this.$store.state.procIdsWithRefresh.push(parentProc.procNameId);
								else this.$store.state.procIdsWithRefreshItem.push(parentProc.procNameId);
								//console.log("this.$store.state.procIdsWithRefreshItem", {...this.$store.state.procIdsWithRefreshItem});
							}
							resolve();
						} else {
							// мы создали новый элемент (в карточке)
							if (res?.page?.meta?.KEYFIELD && res.page.values) {
								// инициализируем его ключевое поле
								this.cardItem[res?.page?.meta?.KEYFIELD] = res.page.values[res?.page?.meta?.KEYFIELD];
							}
							// сообщаем всем, кто DS, что элемент обновлен - они перефетчиваются, если здесь появился элемент
							this.dialogParams.item = {...this.cardItem};
							//console.log("SETTING params.item", this.dialogParams.item);

							if (parentProc) {
								//this.$set(parentProc, "item", this.dialogParams.item);
								// mark parent proc to refresh in full
								if (parentProc.meta?.IS_REOPENAFTERCARD) this.$store.state.procIdsWithRefresh.push(parentProc.procNameId);
							}

							// ждем, что DS карточки перечитался - так как нам важно, чтобы прошел ее registerProc()
							let counter = 0;
							const interval = setInterval(()=>{
								const isLoaded = !this.cardDS.isLoading;
								console.log("Checking DS is reloaded", isLoaded);
								if ( isLoaded || counter++ >= 20 ) {
									clearInterval(interval);
									resolve();
								}
							},250);
						}
					})
					.catch(err => {
						console.error("ERROR", err);
						this.error = err.error;
						reject(err);
					})
					.finally(() => {
						this.isUpdatingSave = false;
						this.isUpdatingSaveAndClose = false;
					});
			});
		},
		fetchForm() {
			this.isLoading = true;
			this.data = null;
			return this.$store.dispatch("get", {
				action: "FormController",
				params: {
					formId: this.dialogParams.formId,
					procNameId: this.dialogParams.id,
				},
			})
				.then(res => {
					this.data = res.page;
					this.$emit("loaded", this.data);
				})
				.catch(err => {
					console.error("ERROR", err);
					this.error = err.error;
				})
				.finally(() => {
					this.isLoading = false;
				});
		},
	},
}
</script>

<style lang="scss">
.proc-dialog-panels {
	@include up($lg) {
		background: $pale-blue-color;
	}

	&__header {
		height: 40px;
		padding: 0 .75rem 0 1.25rem;
		@include up($lg) {
			height: unset;
			padding: 0;
		}
	}

	&__loader {
		background: $table-bg-color;
		height: 100%;
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	&__btns {
		background: $table-bg-color;
		border: 1px solid $black;
	}
}
</style>
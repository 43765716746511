<template>
	<v-dialog v-model="params.isVisible"
			  class="delete-dialog"
			  width="500">
		<v-card>
			<v-card-title>
				{{title}}
			</v-card-title>

			<v-divider/>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
						color="primary"
						@click="onYes">
					Ок
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>

</template>

<script>
	import Vue from "vue";
	import {nf} from "@/utils/string";
	import {debounce} from "lodash";
	import Config from "@/config";

	export default {
		name: "confirm-dialog",
		props: ["params"],
		data: () => ({
			//isLoading: false
		}),
		computed: {
			title() {
				return this.params.title || "Операция успешно выполнена!";
			}
		},
		watch: {
			"params.isVisible"(val, old) {
				if ( !val && old ) {
					this.$emit("yes");
					if ( this.params.callback ) this.params.callback();
				}
			}
		},
		methods: {
			onYes() {
				this.params.isVisible = false;
			},
		}
	}
</script>

<style lang="scss">
	.confirm-dialog {

	}
</style>
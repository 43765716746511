<template>
	<div class="proc-btns" :class="componentClass" ref="container">
		<div class="proc-btns__content">

			<v-btn v-for="(item, index) of effectiveButtons" class="--fake" :key="'btnFake'+index">
				<v-img v-if="item.src" :src="item.src" :title="item.CAPTION"/>
				<span
					v-if="isButtonWithCaption(item)" class="ml-1">{{
						item.CAPTION
					}}</span>
			</v-btn>

			<v-btn v-for="(item, index) of visibleButtons" :key="'btn'+index"
				   :loading="item.isLoading" text
				   :disabled="isButtonDisabled(item)"
				   @click="onButton(item)">
				<v-img v-if="item.src" :src="item.src" :title="item.CAPTION"/>
				<span
					v-if="isButtonWithCaption(item)" class="ml-1">{{
						item.CAPTION
					}}</span>
			</v-btn>

			<div v-if="menuButtons && menuButtons.length" class="ml-2 flex-grow-0">
				<v-menu left offset-y bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn class="proc-btns__menu-btn"
							   icon
							   v-bind="attrs"
							   v-on="on">
							<v-icon>{{isLG ? "mdi-dots-horizontal": "mdi-dots-vertical"}}</v-icon>
						</v-btn>
					</template>

					<v-list dense class="proc-btns__list">
						<v-list-item v-for="(item, index) of menuButtons" :key="'menuBtn'+index"
									 link @click="onButton(item)">
							<v-img v-if="item.src" :src="item.src" :title="item.CAPTION"/>
							<v-list-item-title>{{
									item.CAPTION
								}}
							</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
			</div>
		</div>
	</div>
</template>

<script>

import Config from "@/config";
import Vue from "vue";
import vp from "@/mixins/vp";

export default {
	name: "proc-btns",
	props: {
		buttons: {},
		currentItem: {},
		headers: {},
		updateCounter: {},
	},
	components: {},
	mixins: [vp],
	data: () => ({
		isIniting: true,
		visibleButtons: null,
		menuButtons: null,
	}),
	computed: {
		componentClass() {
			const c = [];
			return c.length ? c : null;
		},
		effectiveButtons() {
			return this.buttons?.filter(btn => !btn._isHidden);
		},
		resizeCounter() {
			return this.$store.state.resizeCounter;
		},
	},
	watch: {
		// 1. обновляем по явной команде
		updateCounter() {
			this.updateButtonsVisibility();
		},
		// 2. обновляем по изменению текущего элемента
		currentItem(val, old) {
			this.updateButtonsVisibility();
		},
		// 3. обновляем по изменению заголовков
		headers() {
			this.updateButtonsVisibility();
		},
		// 3. обновляем по составу кнопок
		buttons() {
			this.updateButtonsVisibility();
		},
		resizeCounter() {
			this.updateView();
		},
	},
	methods: {
		onButton(btn) {
			this.$emit("click", btn);
		},
		updateButtonsVisibility() {
			//console.log("updateButtonsVisibility", this.buttons);

			this.buttons?.forEach(btn => Vue.set(btn, "_isDisabled", false));
			this.buttons?.forEach(btn => Vue.set(btn, "_isHidden", false));

			if (this.currentItem) {
				//console.log("currentItem", Object.keys(this.currentItem));

				Object.keys(this.currentItem).forEach(key => {
					let id, prop, value = false;
					if (key.match(/^OPERVIS\$(\d+)$/i)) {
						id = key.replace(/^OPERVIS\$(\d+)$/i, "$1");
						prop = "_isHidden";
						value = this.currentItem[key] === 0;
					} else if (key.match(/^OPERACT\$(\d+)$/i)) {
						id = key.replace(/^OPERACT\$(\d+)$/i, "$1");
						prop = "_isDisabled";
						value = this.currentItem[key] === 0;
					}
					const btn = this.buttons?.find(el => Math.abs(Number(el.ID_OPERATION)) === Math.abs(Number(id)));
					if (btn) Vue.set(btn, prop, value);
				});
			} else if (this.headers) {
				// visibility is based on ALL items availability
				let ids = [];
				//console.log("headers", this.headers.map(el=>el.PROCPARAM));
				this.headers.forEach(item => {
					if (item.PROCPARAM.match(/^OPERVIS\$(\d+)$/i) || item.PROCPARAM.match(/^OPERACT\$(\d+)$/i)) {
						const id = item.PROCPARAM.replace(/^.+\$(\d+)$/i, "$1");
						//console.log(item.PROCPARAM + " " + id);
						if (!ids.includes(id)) ids.push(Math.abs(Number(id)));
					}
				});
				//console.log("HIDDEN IDS", ids);

				this.buttons?.forEach(btn => {
					if (ids.includes(Math.abs(Number(btn.ID_OPERATION)))) Vue.set(btn, "_isHidden", true);
				});
			}

			// важна паузе, так как кнопки могли еще не отрисоваться
			setTimeout(() => {
				this.updateView();
			}, 50);
		},
		isButtonDisabled(btn) {

			if (btn._isDisabled) return true;
			if (this.currentItem) return false;

			if (btn.ID_OPERATION === Config.ID_OPERATION_FILE_DOWNLOAD) return true;
			if (btn.ID_OPERATION === Config.ID_OPERATION_FILE_OPEN) return true;
			if (btn.ID_OPERATION === Config.ID_OPERATION_FILE_DELETE) return true;

			return false;
		},
		isButtonWithCaption(btn) {
			return !btn.ICON || (btn.IS_TEXTCAPTION && Number(btn.IS_TEXTCAPTION.trim()));
		},
		updateView() {
			//console.log("updateView()", this.effectiveButtons);

			if (!this.effectiveButtons) {
				this.visibleButtons = null;
				this.menuButtons = null;
				//console.log("No effectiveButtons...");
				return;
			}

			if ( this.isLG ) {
				const container = this.$refs.container;
				if (!container) {
					//console.log("No container...");
					return;
				}

				let els = container.querySelectorAll("button.--fake");
				if (!els) {
					//console.log("No fake buttons...");
					return;
				}
				//console.log("ELS", els);

				const cw = container.offsetWidth - 30;
				//console.log("container width " + cw);
				let tw = 0, visibleIndex = -1;
				[...els].forEach((el, index) => {
					const w = el.offsetWidth;
					//console.log(index + ". btn width: " + w);
					if (tw + w < cw) tw += w;
					else if (visibleIndex === -1) visibleIndex = index;
				});
				//console.log("Visible index " + visibleIndex + " of " + els.length);
				//console.log("effectiveButtons", this.effectiveButtons.map(el => el.ID_OPERATION));

				if (visibleIndex >= 0 && visibleIndex < els.length) {
					// smth is not visible
					this.visibleButtons = this.effectiveButtons.slice(0, visibleIndex);
					this.menuButtons = this.effectiveButtons.slice(visibleIndex);
				} else {
					// evrth is visible
					this.visibleButtons = this.effectiveButtons;
					this.menuButtons = null;
				}
			}
			else {
				// mobile view - nothing is visible
				this.visibleButtons = null;
				this.menuButtons = this.effectiveButtons;
			}


		},
		onResize() {
			setTimeout(() => {
				this.updateView();
			}, 50);
		}
	},
	mounted() {
		window.addEventListener("resize", this.onResize);
		this.updateButtonsVisibility();
	},
	beforeDestroy() {
		window.removeEventListener("resize", this.onResize);
	}
}
</script>

<style lang="scss">
.proc-btns {
	flex-grow: 1;
	position: relative;
	overflow: hidden;
	height: 32px;
	margin: 0 4px;

	&__content {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: center;

		@include up($lg) {
			top: 4px;
		}
	}

	.v-btn {
		&:disabled {
			.v-image {
				opacity: 0.25;
				filter: saturate(0);
			}
		}

		&.proc-btns__menu-btn {
			//width: auto;
			@include up($lg) {
				width: 24px;
			}
		}

		&.--fake {
			position: absolute;
			opacity: 0;
			left: -1000px;
			top: -1000px;
		}

		.v-image {
			width: 16px;
			max-width: 16px;
			height: 16px;
		}
	}

	&__list.v-list {
		//padding: 0;

		.v-list-item {
			@include up($lg) {
				min-height: 24px;
			}

			.v-image {
				width: 16px;
				max-width: 16px;
				height: 16px;
				margin-right: 16px;

				@include up($lg) {
					margin-right: 6px;
				}
			}

			&__title {
				white-space: nowrap;
				@include up($lg) {
					font-size: 11px;
				}
			}
		}
	}
}
</style>
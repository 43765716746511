<template>
	<!--<div v-if="isForm" class="panel" :id="id" width="100%" height="100%" :slider-width="12">

		<div class="form" :style="{width:ui.width+'px',height:ui.height+'px'}">
			<h1>form</h1>
		</div>
	</div>-->
	<panel-tabs v-if="hasTabs" :ui="ui" :params="params" :formId="formId" class="panel" :id="id"/>
	<div v-else-if="countChildControls>0" class="panel panel--controls" :id="id+'-controls'">

		<panel-control v-for="(item, index) of controls" :ui="item" :formId="formId"
					   :key="'control_'+item.COMPNAME"
		@error="onControlError"/>

		<!-- child panels -->
		<panel-control v-for="(item, index) of panels" :ui="item" :params="params" :formId="formId"
					   :key="'control_'+item.COMPNAME"
					   @error="onControlError"/>
	</div>
	<DragCol v-else-if="countChildPanels >=2 && ui.isHorizontal" class="panel" :id="id"
			 width="100%" height="100%" :slider-width="12" :leftPercent="child1.size || 50"
			 @isDragging="onDraggingStartStop">
		<template #left>
			<panel :ui="child1" :params="params" :formId="formId"/>
		</template>
		<template #right>
			<panel :ui="child2" :params="params" :formId="formId"/>
		</template>
	</DragCol>
	<DragRow v-else-if="countChildPanels >=2 && ui.isVertical" class="panel" :id="id"
			 width="100%" height="100%" :slider-width="12" :topPercent="child1.size || 50"
			 @isDragging="onDraggingStartStop">
		<template #top>
			<panel :ui="child1" :params="params" :formId="formId"/>
		</template>
		<template #bottom>
			<panel :ui="child2" :params="params" :formId="formId"/>
		</template>
	</DragRow>
	<div v-else-if="hasSinglePanelChild" class="panel panel--single" :id="id">
		<panel :ui="child1" :params="params" :formId="formId" />
	</div>
	<div v-else class="panel panel--proc" :id="id">
		<!--		<panel-child :ui="child1" :params="params" :formId="formId"/>-->
		<div v-if="isDebug" class="panel__ui-name">
			<div>{{ ui.COMPNAME }}</div>
			<div class="caption">{{ ui.width }} x {{ ui.height }} px | {{ ui.size | nf(1) }} %</div>
		</div>

		<proc v-if="ui.ID_PROCNAME"
			  :ui="ui"
			  :id="ui.ID_PROCNAME"
			  :formId="formId"
			  :params="params"
			  @loaded="onProcLoad"
			  @click="onProcClick"
			  @open="onProcOpen"/>
		<!--		<component v-else :is="componentOf(ui)" :ui="ui" :id="id" :params="params" :formId="formId"/>-->
	</div>
</template>

<script>
import {
	DragCol,
	DragRow,
	ResizeCol,
	ResizeRow,
	Resize,
} from "vue-resizer";
import PanelTabs from "@/components/elements/panel-tabs";
import PanelControl from "@/components/elements/panel-control";
import Config from "@/config";

export default {
	name: "panel",
	props: {
		ui: {},
		// здесь структура параметров НЕ совпадает с тем, что непосредстенно у proc
		// смотри panels.vue
		params: {
			type: Array,
			//default: () => []
		},
		formId: {}
	},
	components: {
		DragCol,
		DragRow,
		ResizeCol,
		ResizeRow,
		Resize,
		PanelTabs,
		PanelControl
	},
	data: () => ({}),
	computed: {
		isDebug() {
			return !!this.$route.query?.debugPanels;
		},
		id() {
			return this.ui.COMPNAME;
		},
		children() {
			return this.ui.children;
		},
		countChildPanels() {
			return this.panels?.length || 0;
		},
		hasSinglePanelChild() {
			return this.countChildPanels === 1;
		},
		countChildControls() {
			return this.controls?.length || 0;
		},
		hasTabs() {
			return !!this.ui.children?.find(el => el.COMPNAME.match(/TabSheet/i));
		},
		panels() {
			return this.children?.filter(el => el.COMPNAME.match(/(Panel|Page)/i));
		},
		controls() {
			return this.children?.filter(el => el.COMPNAME.match(Config.RE_KNOWN_CONTROLS));
		},
		child1() {
			return this.children?.length ? this.children[0] : null;
		},
		child2() {
			return this.children?.length ? this.children[1] : null;
		},
		/*procParams() {
			//console.log("PROC PARAMS "+this.ui.ID_PROCNAME, this.params);
			if (this.ui.ID_PROCNAME) {
				//console.log(this.ui.ID_PROCNAME+"PANEL PROC PARAMS", this.params?.length);
				//console.log(this.ui.ID_PROCNAME+":", this.params);
				return this.params?.find(el => el.procNameId === this.ui.ID_PROCNAME)?.params;
			}
		},*/
	},
	watch: {
	},
	methods: {
		/*componentOf(item) {
			if (item.children?.length) {
				if ( item.children.find(el => el.COMPNAME.match(/TabSheet/i)) ) return PanelTabs;
				//if ( item.children.find(el => el.COMPNAME.match(/Panel/i)) ) return Panel;
			}
			return Panel;
		},*/
		onDraggingStartStop(isDragging) {
			if (!isDragging) {
				/*this.ui.children?.forEach(el=>{
					console.log("CHILD RESIZES "+el.ID_PROCNAME);
					if ( el.ID_PROCNAME ) {
						const resizeCounter = this.$store.state.resizeCounters.find(el=>el.procNameId === el.ID_PROCNAME);
						if ( typeof resizeCounter !== "undefined" ) resizeCounter.counter++;
						else this.$store.state.resizeCounters.push({
							procNameId: el.ID_PROCNAME,
							counter: 0
						});

					}
				});*/
				this.$store.state.resizeCounter++;
				//console.log("FORCE RESIZE "+this.$store.state.resizeCounter);
			}
		},
		onProcLoad(data) {
			//this.page = data;
			this.$emit("load", data);
		},
		onProcClick(item) {
			this.$emit("click", item);
		},
		onProcOpen(item) {
			this.$emit("open", item);
		},
		onControlError(error) {
			this.$emit("error", this.ui, error);
		}
	},
}
</script>

<style lang="scss">

.panel {
	position: relative;
	//background: $thead-bg-color;
	width: 100%;
	height: 100%;
	display: flex;

	&--vertical {
		flex-direction: column;
	}

	&__ui-name {
		position: absolute;
		z-index: 2;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, .15);
		padding: 8px;
		color: black;
		font-size: 18px;
		display: none;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		pointer-events: none
	}

	&:hover {
		& > .panel__ui-name {
			display: flex;
		}
	}

	// todo temp - move to a sep component
	.form {
		background: gray;
	}

	&.drager_col {
		.slider_col {
			background: $thead-bg-color;

			&::before {
				transition: background-color .2s;
				position: absolute;
				top: 50%;
				left: 29%;
				transform: translateY(-50%);
				content: "";
				display: block;
				width: 1px;
				height: 24%;
				min-height: 30px;
				max-height: 70px;
				background-color: $border-color;
			}

			&::after {
				transition: background-color .2s;
				position: absolute;
				top: 50%;
				right: 31%;
				transform: translateY(-50%);
				content: "";
				display: block;
				width: 1px;
				height: 24%;
				min-height: 30px;
				max-height: 70px;
				background-color: $border-color;
			}

			&:hover {
				background: $table-bg-color;

				&::before, &::after {
					background-color: $color-black;
				}
			}
		}

		.drager_left {
			padding-right: 6px;
		}

		.drager_right {
			padding-left: 6px;
		}
	}

	&.drager_row {
		.slider_row {
			background: $thead-bg-color;

			&::before {
				transition: background-color .2s;
				position: absolute;
				left: 50%;
				top: 31%;
				transform: translate(-50%);
				content: "";
				display: block;
				height: 1px;
				width: 24%;
				min-width: 30px;
				max-width: 70px;
				background-color: $border-color;
			}

			&::after {
				transition: background-color .2s;
				position: absolute;
				left: 50%;
				bottom: 31%;
				transform: translate(-50%);
				content: "";
				display: block;
				height: 1px;
				width: 24%;
				min-width: 30px;
				max-width: 70px;
				background-color: $border-color;
			}

			&:hover {
				background: $table-bg-color;

				&::before, &::after {
					background-color: $color-black;
				}
			}
		}

		.drager_top {
			padding-bottom: 6px;

			/*& > div {
				display: flex;
				position: relative;
			}*/
		}

		.drager_bottom {
			padding-top: 6px;

			/*& > div {
				display: flex;
				position: relative;
			}*/
		}
	}

	&--controls {
		display: block;
	}
}
</style>

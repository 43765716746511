<template>
	<v-app>
		<transition name="route" mode="out-in" appear>
			<router-view/>
		</transition>
	</v-app>
</template>

<script>
export default {
	name: 'App',

	data: () => ({
		//
	}),
	methods: {
		onResize() {
			this.$store.commit('updateWindowWidth');
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		},
	},
	mounted() {
		window.addEventListener("resize", this.onResize);
		this.onResize();
	},
	beforeDestroy() {
		window.removeEventListener("resize", this.onResize);
	}
};
</script>

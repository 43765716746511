<template>
	<div class="time-picker">
		<v-select v-model="hours" :items="hourItems" dense hide-details @change="onChange"/>
		<span>:</span>
		<v-select v-model="minutes" :items="minuteItems" dense hide-details @change="onChange"/>
	</div>

</template>

<script>
import {iso2hm, iso2hms} from "@/utils/date";

export default {
	name: "time-picker",
	props: {value:{}},
	data: () => ({
		hours: "00",
		minutes: "00",
		internalValue: null,
		hourItems: [...Array(24).keys()].map(el => ('00' + el).slice(-2)),
		minuteItems: [...Array(60).keys()].map(el => ('00' + el).slice(-2)),
	}),
	computed: {
	},
	watch: {
		value: {
			immediate: true,
			handler() {
				if (this.value) {
					this.internalValue = this.value;
					this.hours = this.internalValue.substring(0, 2);
					this.minutes = this.internalValue.substring(3, 5);
				}
				else {
					this.internalValue = "00:00:00";
					this.hours = "00";
					this.minutes = "00";
				}
			}
		}
	},
	methods: {
		onChange() {
			this.internalValue = this.hours+":"+this.minutes+":00";
			this.$emit("input", this.internalValue);
			this.$emit("change", this.internalValue);
		}
	}
}
</script>

<style lang="scss">
.time-picker {
	display: flex;
	width: 10em;
	align-items: center;
	justify-content: space-between;

	.v-input {
		flex-grow: 0;
		width: 4em !important;
		font-size: 14px !important;

		.v-select {
			&__selection--comma {
				margin-left: 4px !important;
			}
		}
	}
}
</style>
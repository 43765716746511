import Vue from 'vue';
import config from "../config";
import headful from "headful";
import router from '../router/index';
import store from "@/store/index";
import {routeOfMenu} from "@/utils/utils";

const mutations = {
    setResponse: (state, payload) => {
        if (!state.response) state.response = {};
        if (!payload) state.response = {};
        for (const actionName in payload) {
            //console.log("SET RESPONSE", actionName);
            Vue.set(state.response, actionName, payload[actionName]);
        }
        mutations.setSession(state, payload);
        mutations.setMenuItems(state);
    },
    setPage: (state, payload) => {
        if (payload?.page) {
            state.page = {};

            for (const key in payload.page) {
                /*if (payload.page[actionName]) {
                    const {title, keywords, description} = payload.page[actionName];
                    [
                        ["title", title],
                        ["keywords", keywords],
                        ["description", description],
                        //["url", config.LIVE_HOST + self.location.pathname],
                    ].forEach(([key, value]) => {
                        if (value) {
                            headful({
                                [key]: value,
                            });
                        }
                    });
                }*/

                Vue.set(state.page, key, payload.page[key]);

                const {title, keywords, description} = payload.page;
                [
                    ["title", title],
                    ["keywords", keywords],
                    ["description", description],
                    //["url", config.LIVE_HOST + self.location.pathname],
                ].forEach(([key, value]) => {
                    if (value) {
                        headful({
                            [key]: value,
                        });
                    }
                });
            }
        }
        if (payload?.common) {
            for (const key in payload.common) {
                // внимание, такой подход не реактивится:
                //state.page[key] = payload.page[key];
                // поэтому явным образом вызываем Vue.set():
                Vue.set(state.common, key, payload.common[key]);
            }
        }
        if (payload?.error) {
            Vue.set(state.error, payload?.error);
        }
    },
    setError: (state, payload) => {
        state.error = payload;
    },
    setSession: (state, payload) => {
        //console.log("Session payload: ", payload);
        if (payload?.session) {
            if (!state.session) state.session = {};
            Vue.set(state.session, "user", payload.session.user);

            if (payload.session.auth) {
                Vue.set(state.session, "auth", payload.session.auth);
                localStorage.setItem(config.STORAGE_AUTH_TOKEN, state.session.auth);
                //console.log("Auth set: " + state.session.auth);
            } else {
                localStorage.removeItem(config.STORAGE_AUTH_TOKEN);
                //console.log("Auth unset");
                // TODO: решить, нужен ли здесь редирект на auth - см. router.beforeEach()
                //router.push({name: "auth"});
            }

            // если есть session TTL
            /*if (payload.session.user?.LOGIN && payload.session.user?.WEBSESSION_TTL) {
                // renew session TTL
                clearTimeout(state.sessionTimeout);
               /!* state.sessionTimeout = setTimeout(() => {
                    // end session
                    router.push({name: "session-expired"});
                }, payload.session.user?.WEBSESSION_TTL * 60 * 1000);*!/
                state.sessionLastTime = new Date();
                console.log("SESSION RESTARTED");
            }*/
        }
        // важно! если сессия не пришла совсем - ничего не переустанавливаем в storage и state
        // else {
        // 	localStorage.removeItem(config.STORAGE_AUTH_TOKEN);
        // 	console.log("Auth unset");
        // }
    },
    setRouteName: (state, payload) => {
        //console.log("Current route: ", payload);
        state.currentRouteName = payload;
    },

    setMenuItems: (state) => {
        //if (state.menuItems?.length) return;

        const menuMap = (items) => {
            return items?.map(el => ({
                id: el.ID_MENU,
                title: el.CAPTION,
                icon: null,//'mdi-star-outline',
                items: itemsOf(el),
                isCard: el.IS_CARD,
                to: routeOfMenu(el) || {name: "under"},
                openOnHover: false
            }));
        };
        const itemsOf = (item) => {
            return menuMap(state.common.menus.filter(el => el.ID_PARENT === item.ID_MENU));
        };

        // need a pause as common.menus is not inited instantly due to Vue.set();
        setTimeout(() => {
            // take parentless elements as root menu items
            Vue.set(state, "menuItems", menuMap(state.common?.menus?.filter(el => !el.ID_PARENT)));
            //console.log("menuItems set");
        }, 50);
    },

    updateWindowWidth: (state) => {
        state.windowWidth = window.innerWidth;
    },

    logout(state, payload) {
        localStorage.removeItem(config.STORAGE_AUTH_TOKEN);
        state.sessionLastTime = null;
        clearTimeout(state.sessionTimeout);
        console.log("SESSION DESTROYED");
    },
};

export default mutations;

export function period(date1, date2) {
    let diff = date2.getTime() - date1.getTime();

    let days = Math.floor(diff / (1000 * 60 * 60 * 24));
    diff -= days * (1000 * 60 * 60 * 24);

    let hours = Math.floor(diff / (1000 * 60 * 60));
    diff -= hours * (1000 * 60 * 60);

    let minutes = Math.floor(diff / (1000 * 60));
    diff -= minutes * (1000 * 60);

    let seconds = Math.floor(diff / (1000));
    diff -= seconds * (1000);

    return {
        days,
        hours,
        minutes,
        seconds,
    };
}

export function date2dmy(date) {
    if (!date) return "";
    if (typeof (date) === "number") date = new Date(date);
    let day = ('00' + date.getDate()).slice(-2);
    let month = ('00' + (parseInt(date.getMonth()) + 1)).slice(-2);
    let year = date.getFullYear();
    return day + '.' + month + '.' + year;
}

export function date2hm(date) {
    let hours = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours());
    let minutes = "0" + date.getMinutes();
    return hours + ':' + minutes.substr(-2);
}

export function date2hms(date) {
    if (!date) return "";
    if (typeof (date) === "number") date = new Date(date);
    let hours = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours());
    let minutes = "0" + date.getMinutes();
    let seconds = "0" + date.getSeconds();
    return hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
}

export function date2dmyhms(date) {
    let day = date2dmy(date);
    let time = date2hms(date);
    return day + ' ' + time;
}

export function date2dmyhm(date) {
    let day = date2dmy(date);
    let time = date2hm(date);
    return day + ' ' + time;
}

export function date2Iso(date) {
    if (!date) return null;
    if (typeof (date) === "number") date = new Date(date);
    return date.toISOString().slice(0, 19).replace('T', ' ');
}

/*export function date2Iso(date) {
    if (typeof (date) === "number") date = new Date(date);
    let day = ('00' + date.getDate()).slice(-2);
    let month = ('00' + (parseInt(date.getMonth()) + 1)).slice(-2);
    let year = date.getFullYear();
    return year + '-' + month + '-' + day;
}*/

export function iso2Date(iso) {
    if (!iso
        || typeof (iso) === "undefined"
        || !iso.match(/^\d{4}-\d{2}-\d{2}( \d{2}:\d{2})?/)) return null;
    let dtParts = iso.split(" ");
    let dateParts = dtParts[0].split("-");
    let date;
    if (dtParts[1]) {
        let timeParts = dtParts[1].split(":");
        date = new Date(parseInt(dateParts[0]),
            dateParts[1] - 1,
            parseInt(dateParts[2].substr(0, 2)),
            (timeParts[0] ? parseInt(timeParts[0]) : 0),
            (timeParts[1] ? parseInt(timeParts[1]) : 0),
            (timeParts[2] ? parseInt(timeParts[2]) : 0));
    } else {
        date = new Date(parseInt(dateParts[0]),
            dateParts[1] - 1,
            parseInt(dateParts[2].substr(0, 2)),
            0,
            0,
            0);
    }
    return date;
}

export function iso2ymd(str) {
    if (!str) return str;
    return str?.substr(0, 10);
}

export function iso2hm(date) {
    if (!date) return date;
    let hour = ('00' + date.substring(11, 13)).slice(-2);
    let min = ('00' + date.substring(14, 16)).slice(-2);
    return hour + ':' + min;
}

export function iso2hms(date) {
    if (!date) return date;
    let hour = ('00' + date.substring(11, 13)).slice(-2);
    let min = ('00' + date.substring(14, 16)).slice(-2);
    let sec = ('00' + date.substring(17, 19)).slice(-2);
    return hour + ':' + min + ':' + sec;
}

export function hms2hm(time) {
    if (!time) return "";
    return time.substring(0, 5);
}

export function dmy2dmry(dmy) {
    if (!dmy) return "";
    let date = ddmmyyyy2Date(dmy);
    return ddMonthYYYY(date);
}

export function dmy2dmryCut(dmy) {
    if (!dmy) return "";
    let date = ddmmyyyy2Date(dmy);
    return ddCutMonthYYYY(date);
}

/**
 * 25.04.2023 -> 2023-04-25
 * @param date
 * @returns {string}
 */
export function dmy2ymd(date) {
    if (!date) return '';
    let day = ('00' + date.substring(0, 2)).slice(-2);
    let month = ('00' + date.substring(3, 5)).slice(-2);
    let year = date.substring(6, 10);
    return year + '-' + month + '-' + day;
}

export function dmyhms2Iso(str) {
    if (!str) return str;
    let time = "00:00:00";
    if (str.match(/^\d{2}\.\d{2}\.\d{4} \d{2}:\d{2}:\d{2}$/)) {
        time = str.replace(/^\d{2}\.\d{2}\.\d{4} (\d{2}:\d{2}:\d{2})$/, "$1");
    }
    return dmy2ymd(str) + " " + time;
}

export function dmyhm2Iso(str) {
    if (!str) return str;
    let time = "00:00";
    if (str.match(/^\d{2}\.\d{2}\.\d{4} \d{2}:\d{2}(:\d{2})?$/)) {
        time = str.replace(/^\d{2}\.\d{2}\.\d{4} (\d{2}:\d{2}).*/, "$1");
    }
    return dmy2ymd(str) + " " + time;
}

/**
 * 2023-04-25 -> 25.04.2023
 * @param date
 * @returns {string}
 */
export function ymd2dmy(date) {
    if (!date) return '';
    let day = ('00' + date.substring(8, 10)).slice(-2);
    let month = ('00' + date.substring(5, 7)).slice(-2);
    let year = date.substring(0, 4);
    return day + '.' + month + '.' + year;
}

export function dmy2date(dmy) {
    if (typeof (dmy) === "undefined"
        || !dmy.match(/^\d{2}.\d{2}.\d{4}/)) return null;
    let dateParts = dmy.split("\.");
    let date = new Date(parseInt(dateParts[2]),
        dateParts[1] - 1,
        parseInt(dateParts[0].substr(0, 2))
    );
    return date;
}

export function dmyhm2date(dmyhm) {
    if (typeof (dmyhm) != "string"
        || !dmyhm.match(/^\d{2}\.\d{2}\.\d{4} \d{1,2}:\d{2}/)) return null;
    return dmyhms2Date(dmyhm + ":00");
}

export function dmyhms2Date(dmyhms) {
    if (typeof (dmyhms) === "undefined"
        || !dmyhms.match(/^\d{2}\.\d{2}\.\d{4} \d{1,2}:\d{2}:\d{2}/)) return null;
    let dtParts = dmyhms.split(" ");
    let dateParts = dtParts[0].split(".");
    let date = dmy2date(dtParts[0]);
    if (date && dtParts[1]) {
        let timeParts = dtParts[1].split(":");
        date.setHours(timeParts[0] ? parseInt(timeParts[0]) : 0);
        date.setMinutes(timeParts[1] ? parseInt(timeParts[1]) : 0);
        date.setSeconds(timeParts[2] ? parseInt(timeParts[2]) : 0);
    }
    return date;
}

export function dmyhm2hm(dmyhm) {
    if (!dmyhm) return '';
    let hour = ('00' + dmyhm.substring(11, 13)).slice(-2);
    let minute = ('00' + dmyhm.substring(14, 16)).slice(-2);
    return hour + ':' + minute;
}

export function iso2dmy(str) {
    if (!str) return '';
    return date2dmy(iso2Date(str));
}

export function iso2dmyhms(str) {
    if (!str) return '';
    return date2dmyhms(iso2Date(str));
}

export function iso2dmyhm(str) {
    if (!str) return '';
    return date2dmyhm(iso2Date(str));
}

// TODO: refactor namings as above

export function ddmmyyyy2Date(dmy) {
    if (typeof (dmy) === "undefined"
        || !dmy.match(/^\d{2}.\d{2}.\d{4}/)) return null;
    let dateParts = dmy.split(".");
    let date = new Date(parseInt(dateParts[2]),
        dateParts[1] - 1,
        parseInt(dateParts[0].substr(0, 2))
    );
    return date;
}

export function iso2DDMMYYYY(date) {
    if (!date) return '';
    let day = ('00' + date.substring(8, 10)).slice(-2);
    let month = ('00' + date.substring(5, 7)).slice(-2);
    let year = date.substring(0, 4);
    return day + '.' + month + '.' + year;
}

export function date2DDMMYYYY(date) {
    if (typeof (date) === "number") date = new Date(date);
    let day = ('00' + date.getDate()).slice(-2);
    let month = ('00' + (parseInt(date.getMonth()) + 1)).slice(-2);
    let year = date.getFullYear();
    return day + '.' + month + '.' + year;
}

export function date2DDMM(date) {
    if (typeof (date) === "number") date = new Date(date);
    let day = ('00' + date.getDate()).slice(-2);
    let month = ('00' + (parseInt(date.getMonth()) + 1)).slice(-2);
    return day + '.' + month;
}

export function date2HHMM(date) {
    let hours = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours());
    let minutes = "0" + date.getMinutes();
    return hours + ':' + minutes.substr(-2);
}

export function ts2HHMM(ts) {
    return date2HHMM(new Date(ts));
}

export function ddMonthYYYY(date) {
    if (!date) return '';
    let year = date.getFullYear();
    return ddMonth(date) + " " + year;
}

export function ddCutMonthYYYY(date) {
    if (!date) return '';
    let year = date.getFullYear();
    return ddCutMonth(date) + " " + year;
}

export function ddMonth(date) {
    if (!date) return '';

    let months = [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря',
    ];
    let day = date.getDate();
    let monthIndex = date.getMonth();

    return day + ' ' + months[monthIndex];
}

export function ddCutMonth(date) {
    if (!date) return '';

    let months = [
        'янв.',
        'фев.',
        'марта',
        'апр.',
        'мая',
        'июня',
        'июля',
        'авг.',
        'сен.',
        'окт.',
        'нояб.',
        'дек.',
    ];
    let day = date.getDate();
    let monthIndex = date.getMonth();

    return day + ' ' + months[monthIndex];
}

export function daysInMonth(monthIndex, year) {
    if (!year) year = new Date().getFullYear();
    return new Date(year, monthIndex + 1, 0).getDate();
}

export function monthName(index) {
    let months = [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
    ];
    return months[index];
}

export function monthIndex(month) {
    let months = [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
    ];
    return months.findIndex(el => el === month);
}

export function weekDaysShort() {
    return [
        "Пн",
        "Вт",
        "Ср",
        "Чт",
        "Пт",
        "Сб",
        "Вс",
    ];
}

export function weekDays() {
    return [
        'Понедельник',
        'Вторник',
        'Среда',
        'Четверг',
        'Пятница',
        'Суббота',
        'Воскресение'
    ];
}

export function dayOfWeek(date) {
    if (!date) return '';
    let day = date.getDay();
    if (day === 0) return weekDays()[6];
    return weekDays()[day - 1];
}

export function dayOfWeekShort(date) {
    if (!date) return '';
    let day = date.getDay();
    if (day === 0) return weekDaysShort()[6];
    return weekDaysShort()[day - 1];
}

export function isSameDate(d1, d2) {
    if (!d1 || !d2) return false;
    return d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate();
}

export function isSameMonth(d1, d2) {
    if (!d1 || !d2) return false;
    return d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth();
}
<template>
	<v-dialog v-model="params.isVisible" width="720"
			  :fullscreen="!isLG"
			  :scrollable="!isLG"
			  content-class="proc-report-item-dialog">
		<v-card class="proc-report-item-dialog__card">
			<v-card-title class="d-flex justify-space-between">
				Получение отчета
				<v-btn icon @click="params.isVisible=false">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>

			<v-card-text class="proc-report-item-dialog__body">
				<v-row>
					<v-col cols="12" md="4" class="proc-report-item-dialog__left">
						<ul>
							<li :class="{done:step>=0}">
								<v-icon>mdi-check</v-icon>
								Получение списка
							</li>
							<li :class="{done:step>=1}">
								<v-icon>mdi-check</v-icon>
								Выбор отчета
							</li>
							<li :class="{done:step>=2}">
								<v-icon>mdi-check</v-icon>
								Установка параметров
							</li>
							<li :class="{done:step>=3}">
								<v-icon>mdi-check</v-icon>
								Выполнение запроса
							</li>
							<li :class="{done:step>=4}">
								<v-icon>mdi-check</v-icon>
								Ожидание результата <span v-if="retry">({{ retry }})</span></li>
							<li :class="{done:step>=5}">
								<v-icon>mdi-check</v-icon>
								Получение файла
							</li>
							<li :class="{done:step>=6}">
								<v-icon>mdi-check</v-icon>
								Подписание
							</li>
							<li :class="{done:step>=7}">
								<v-icon>mdi-check</v-icon>
								Сохранение файла
							</li>
						</ul>
					</v-col>

					<v-col cols="12" md="8" class="proc-report-item-dialog__content">

						<div class="proc-report-item-dialog__content-doc">
							<div v-if="error" class="d-flex flex-column align-center">
								<h3>{{ error }}</h3>
								<p>Пожалуйста, повторите попытку позже</p>
								<v-btn @click="onRestart">Повторить</v-btn>
							</div>
							<div v-else-if="isLoading" class="d-flex flex-column align-center">
								<v-progress-circular indeterminate
													 color="grey lighten-2"/>
								<p class="mt-8">Пожалуйста, подождите...</p>
							</div>
							<div v-else-if="items&&step===1" class="proc-report-item-dialog__content-table">
								<p>Пожалуйста, выберите отчет:</p>
								<v-data-table :headers="headers"
											  :items="items"
											  :items-per-page="-1"
											  :item-class="itemClass"
											  hide-default-footer
											  fixed-header
											  dense
											  @click:row="onItemClick"/>
							</div>
							<div v-else-if="isSigned" class="d-flex flex-column align-center">
								<v-icon x-large color="green">mdi-check</v-icon>
								<h3>Файл успешно подписан</h3>
								<v-btn @click="params.isVisible = false" color="primary" class="mt-8">Завершить</v-btn>
							</div>
							<template v-else-if="pdf.url">
								<vue-pdf-embed :source="pdf.url" :page="pdf.page"
											   @click.native="onSign"
											   @loaded="onPdfLoaded"/>
								<div v-if="pdf.isLoaded" class="controls">
									<span class="page-info">Страница: {{ pdf.page }} из {{ pdf.totalPages }}</span>
									<v-icon v-if="pdf.page>1" class="arrow-prev" @click="onPrevPage">
										mdi-arrow-left-circle-outline
									</v-icon>
									<v-icon v-if="pdf.page<pdf.totalPages" class="arrow-next" @click="onNextPage">
										mdi-arrow-right-circle-outline
									</v-icon>
									<div class="btns">
										<v-btn @click="onDownload">Скачать</v-btn>
										<v-btn @click="onSign" color="primary">Подписать</v-btn>
									</div>
								</div>
							</template>
						</div>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<proc-params-dialog :params="paramsDialog"/>
		<proc-report-signature-dialog :params="signatureDialog" @save="onSave"/>
	</v-dialog>
</template>

<script>
import ProcParamsDialog from "@/components/elements/proc-params-dialog";
import ProcReportSignatureDialog from "@/components/elements/proc-report-signature-dialog";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';
import {crossDownload} from '@/utils/utils';
import Config from "@/config";
import Vue from "vue";
import {iso2DDMMYYYY} from "@/utils/date";
import vp from "@/mixins/vp";

const REPORT_MAX_RETRIES = 10;
const REPORT_RETRY_PAUSE = 3000;

export default {
	name: "proc-report-item-dialog",
	mixins: [vp],
	components: {ProcParamsDialog, VuePdfEmbed, ProcReportSignatureDialog},
	props: ["params"],
	data: () => ({
		isLoading: false,
		error: null,
		requestId: null,
		requestParams: null,
		step: 0,
		retry: 0,
		isDone: false,
		items: null,
		headers: [
			{text: "№", value: "index", width: "10%", sortable: false},
			{text: "Отчет", value: "REPORT", width: "90%", sortable: false},
			//{text: "REPORTNAMEFOREXPORT", value: "REPORTNAMEFOREXPORT", width: "90%", sortable: false}
		],
		selectedItem: null,
		itemDialog: {
			isVisible: false
		},
		paramsDialog: {
			isVisible: false,
			params: null,
			dicts: null,
			item: null,
			callback: null,
		},
		pdf: {
			url: null,
			page: 1,
			isLoaded: false
		},
		signatureDialog: {
			visible: false,
			url: null
		},
		isSigned: false
	}),
	watch: {
		"params.isVisible": {
			immediate: true,
			handler(visible, old) {
				if (visible && visible !== old) {
					this.init();
				} else if (!visible && visible !== old) {
					this.deinit();
				}
			}
		},
	},
	computed: {
		reportFilename() {
			let filename = "report.pdf";
			if (this.params?.params && this.selectedItem?.REPORTNAMEFOREXPORT) {
				const suffix = this.params.params.find(el => el.INPARAMNAME === "SYS_REPORTFILENAME")?.VALUE;
				if (suffix) filename = this.selectedItem.REPORTNAMEFOREXPORT + suffix;
			}
			return filename;
		}
	},
	methods: {
		init() {
			this.deinit();
			this.isLoading = true;
			this.fetchList();
		},
		deinit() {
			clearTimeout(this.waitTimout);
			this.requestId = null;
			this.step = 0;
			this.retry = 0;
			this.isLoading = false;
			this.error = null;
			this.items = null;
			this.selectedItem = null;
			this.isSigned = false;
			this.pdf.url = null;
			this.pdf.isLoaded = false;
		},
		fetchList() {
			this.isLoading = true;
			this.$store.dispatch("get", {
				action: "ReportListController",
				params: {
					id: this.params.procNameId,
				},
			})
				.then(res => {
					this.items = res.page.items.map((el, index) => ({...el, index: index + 1}));
					this.$emit("loaded", this.proc);
					this.step = 1;
				})
				.catch(err => {
					console.error("ERROR", err);
					this.error = err.error;
				})
				.finally(() => {
					this.isLoading = false;
				});
		},
		initRequest() {
			this.isLoading = true;
			this.$store.dispatch('post', {
				action: 'ReportItemController',
				params: {
					id: this.selectedItem.ID_REPORT,
					procNameId: this.params.procNameId,
					formId: this.params.formId,
					//params: this.params.params,
					//agentId: this.params.item.ID_AGENT,
				}
			}).then((res) => {
				//console.log("RES", res);
				this.requestId = res.page.requestId;

				// init report request params and show param dialog
				this.initRequestParams(res.page?.paramSet);
				const params = this.requestParams.filter(el => el.CASE_TYPE.trim() === 'M');
				if (params.length) {
					// запрос ручных параметров
					this.paramsDialog.params = params;	// todo check this should be paramSet
					this.paramsDialog.paramSet = params;	// todo check this should be paramSet
					//console.log("SETTING dialog paramSet", this.paramsDialog.paramSet);
					this.paramsDialog.dicts = res.page?.dicts;
					this.paramsDialog.isVisible = true;
					this.paramsDialog.callback = async () => {
						this.paramsDialog.isVisible = false;
						this.makeRequest();
					}
				} else {
					// сразу запрос, без запроса ручных параметров
					this.makeRequest();
				}
			}).catch((error) => {
				this.onError(error);
			}).finally(() => {
			});
		},
		makeRequest() {
			this.step = 3;
			this.isLoading = true;
			this.$store.dispatch('put', {
				action: 'ReportItemController',
				params: {
					requestId: this.requestId,
					procNameId: this.params.procNameId,
					params: this.requestParams,
				}
			}).then((res) => {
				//console.log("RES", res);
				this.watchReportStatus();
			}).catch((error) => {
				this.onError(error);
			}).finally(() => {
				//this.isLoading = false;
			});
		},
		watchReportStatus() {
			this.step = 4;
			this.retry++;
			this.error = null;

			this.$store.dispatch('get', {
				action: 'ReportItemController',
				params: {
					retry: this.retry,
					requestId: this.requestId
					//id: this.params.item.ID_NODEACT,
					//agentId: this.params.item.ID_AGENT,
				}
			}).then((res) => {
				this.previewFile();
			}).catch((error) => {
				// need retry, todo check error code
				if (error.code === Config.ERROR_CODE_NOT_READY) {

					if (this.retry >= REPORT_MAX_RETRIES) {
						// timeout
						this.onError(error.data?.PROGRESSMESSAGE || "Нет связи с сервером отчетов");
					} else {
						// retry
						this.waitTimout = setTimeout(() => {
							this.watchReportStatus();
						}, REPORT_RETRY_PAUSE);
					}
				} else this.onError(error);
			}).finally(() => {
				//this.isLoading = false;
			});
		},
		previewFile() {
			this.step = 5;
			this.isLoading = false;
			this.isDone = true;

			let authToken = localStorage.getItem(Config.STORAGE_AUTH_TOKEN);
			this.pdf.url = "/api/ReportDownloadController?auth=" + authToken + "&requestId=" + this.requestId;
			/*crossDownload(url)
				.then(res => {
				})
				.catch(error => {
					this.onError(error);
				});*/
		},
		itemClass(item, index) {
			//if (item.isDeleted) return "deleted";
			const c = [];
			//if (this.isFiltering) c.push("filtering");
			if (this.selectedItem === item) c.push("current");
			//if (!this.readonly && this.selectedItemIndex === null && index === 0) c.push("edit");
			return c.length ? c : null;
		},
		initRequestParams(paramSet) {
			this.requestParams = [];

			//console.log("params.params", this.params.params);
			//console.log("paramSet", paramSet);

			paramSet?.forEach(param => {
				if (param.CASE_TYPE.trim() === 'S') {
					// ищем такое св-во в выбранном элементе в родительской выборке
					const p = this.params?.params?.find(el => el.INPARAMNAME === param.PROCNAMESOURCEKEYFLD);
					if (p) {
						// there is value from external params
						if (typeof p.VALUE !== "undefined") {
							let value = p.VALUE;
							// даты YYYY-MM-DD транлсируем в DD-MM-YYYY todo придумать место обработки получше
							if (typeof value === "string" && value.match(/^\d{4}-\d{2}-\d{2}/)) value = iso2DDMMYYYY(value);
							Vue.set(param, "VALUE", value);
						}
					}
				} else if (param.CASE_TYPE.trim() === 'D') {
					Vue.set(param, "VALUE", param.DEFAULTVLS);
				}
				this.requestParams.push(param);
			});

			//console.log("this.requestParams", this.requestParams);
		},
		onItemClick(item) {
			if (this.selectedItem) return;
			this.selectedItem = item;
			this.step = 2;
			this.initRequest();
		},
		onError(error) {
			this.error = error;
		},
		onRestart() {
			this.init();
		},
		onPdfLoaded(pdf) {
			this.pdf.isLoaded = true;
			this.pdf.totalPages = pdf._pdfInfo.numPages;
		},
		onPrevPage() {
			if (this.pdf.page > 1) this.pdf.page--;
		},
		onNextPage() {
			if (this.pdf.page < this.pdf.totalPages) this.pdf.page++;
		},
		onDownload() {
			const url = "/api/ReportDownloadController?requestId=" + this.requestId
				+ "&filename=" + encodeURIComponent(this.reportFilename + ".pdf");
			//+"&filename="+this.reportFilename;
			crossDownload(url)
				.then(res => {
				})
				.catch(error => {
					this.onError(error);
				});
		},
		onSign() {
			this.signatureDialog.visible = true;
			this.signatureDialog.url = this.pdf.url;
		},
		onSave(data) {
			this.isLoading = true;
			this.step = 7;

			this.$store.dispatch('put', {
				action: 'ReportSignatureController',
				params: {
					requestId: this.requestId,
					data
				}
			}).then((res) => {
				this.isSigned = true;
				this.step = 8;

				// todo temp - download image
				const link = document.createElement('a');
				document.body.appendChild(link);
				link.download = this.reportFilename + ".png";
				link.href = data;
				link.click();
				document.body.removeChild(link); //remove the link when done
			}).catch((error) => {
				this.onError(error);
			}).finally(() => {
				this.isLoading = false;
			});
		}
	},
}
</script>

<style lang="scss">
.proc-report-item-dialog {

	&__card {
		display: flex;
		flex-direction: column;
	}

	&__body {
		//height: 240px;
		display: flex;
		flex-direction: column;

		@include up($lg) {
			height: 620px;
		}

		& > .row {
			height: 100%;
		}
	}

	&__left {
		//flex-basis: 30%;

		ul {
			list-style: none;
			margin: 16px 0;
			padding: 0;

			li {
				margin: 16px 0;
				padding: 0;

				&.done {
					font-weight: bold;
					color: green;

					.v-icon {
						opacity: 1;
					}
				}

				.v-icon {
					margin-right: 4px;
					opacity: 0;
				}
			}
		}
	}

	&__content {
		position: relative;

		@include up($lg) {
			height: 100%;
		}


		&-doc {
			background: $table-bg-color;
			border: 1px solid $border-color;
			//height: 100%;
			padding: 20px;
			box-sizing: border-box;
			overflow-x: auto;
			overflow-y: auto;
			display: flex;
			justify-content: center;
			align-items: center;

			@include up($lg) {
				height: 100%;
				padding: 10px 10px 40px 10px;
			}
		}

		&-table {
			width: 100%;
			height: 100%;

			.v-data-table {
				border: $border;

				table {
					& > tbody {

						& tr {
							cursor: pointer;

							&.current {
								& > td {
									background: $cell-bg-color--selected;
								}
							}

							&:hover:not(.current):not(.v-data-table__empty-wrapper) {
								> td {
									background: $tr-hover-color;
								}
							}
						}
					}
				}
			}
		}

		.controls {
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;

			.page-info {
				position: absolute;
				left: 50%;
				top: 40px;
				transform: translateX(-50%);
				text-align: center;
			}

			.arrow-prev {
				position: absolute;
				left: 40px;
				top: 50%;
				transform: translateX(-50%) translateY(-50%);
			}

			.arrow-next {
				position: absolute;
				right: 40px;
				top: 50%;
				transform: translateX(50%) translateY(-50%);
			}

			.btns {
				position: absolute;
				left: 50%;
				bottom: 40px;
				transform: translateX(-50%);
				display: flex;
				justify-content: center;
				text-align: center;

				.v-btn {
					margin: 0 10px;
				}
			}
		}
	}
}
</style>